import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Isvg from "react-inlinesvg";
import Page from "../containers/page";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { API_ENDPOINT, SITE_URL } from "../constants";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import Img from '../components/Img';


import ContactForm from "../components/forms/contactForm";
import ContactForm1 from "../components/forms/contactForm34.js";
import AOS from "aos";
import "aos/dist/aos.css";
import CuroflowIcon from "../assets/images/Curoflow_Digital_Telemedicine_Platform_Icon.png";
import SupportForm from "../components/forms/supportForm3";
import translateLinkToLang from "../translateLinkToLang.js";

import AppImg from "../assets/images/appImg.png"

var striptags = require("striptags");

function parseJSON(response) {
  return response.json().then((json) => {
    return {
      result: json,
      status: response.status,
    };
  });
}

class OurSolutions extends Component {
  constructor(props) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.player = React.createRef();

    this.formRef = React.createRef();

    this.state = {
      promotedProjects: [],
      poolActive: false,
      chatForm: false,
      yScroll2: 0,
      ourSolutions: [],
      solutionsStatus: false,
      pageData: null,
      ...props.initialData,
      infoApp: {
        imageLogo: '',
        imagePathLogo: '',
        imageName: '',
        imagePath: '',
        color1: '',
        color2: '',
        textButton1: '',
        textButton2: '',
        textButton3: '',
        textButton4: ''
      },

      checkedText1: false,
      checkedText2: false,
      checkedText3: false,
      checkedText4: false,
      errorFields: {
        imageLogoError: false,
        imagePathError: false,
        colorError: false,
        textButtonError: false,
        textButtonError1: false,
        textButtonError2: false,
        textButtonError3: false,
        textButtonError4: false
      },
      currentStep: 1,
      errorTypeLogo: false,
      errorTypeImage: false,
      sendMessage3Animation: false
    };
  }

  listenToScroll = () => {
    const yScroll2 =
      document.body.scrollTop || document.documentElement.scrollTop;

    this.setState({
      yScroll2: yScroll2,
    });
  };

  sendMessage1 = (data) => {
    if (data.checked) {
      data.lang = this.props.lang;
      this.setState(
        {
          loading1: true,
        },
        () => {
          fetch(API_ENDPOINT + "/data/contact/new", {
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({ data }),
          })
            .then(parseJSON)
            .then(({ result, status }) => {
              if (result.error) {
                this.setState({ error: result.error });
              } else {
                this.setState(
                  {
                    supportForm: false,
                  },
                  () => {
                    const path = "thank-you";
                    this.props[0].history.push(`/${path}`);
                  }
                );
              }
            });
        }
      );
    }
  };





  sendMessage2 = (data) => {

    console.log('data', data);


    this.props.setRecaptchaCallback(() => {

      if (data && data.checked && this.props.allowSubmit) {
        if (data.email && (data.email.toLowerCase().indexOf('@gmail.com') !== -1 || data.email.toLowerCase().indexOf('@live.com') !== -1 || data.email.toLowerCase().indexOf('@mailinator.com') !== -1 || data.email.toLowerCase().indexOf('@jollyfree.com') !== -1 || data.email.toLowerCase().indexOf('@gotgel.org') !== -1 || data.email.toLowerCase().indexOf('@yahoo.com') !== -1 || data.email.toLowerCase().indexOf('@hotmail.com') !== -1 || data.email.toLowerCase().indexOf('@business.com') !== -1 || data.email.toLowerCase().indexOf('@admin.com') !== -1 || data.email.toLowerCase().indexOf('@info.com') !== -1)) {
          return this.setState({ contactFormError: 'Please fill in your work email' });
        }
        else this.setState({ contactFormError: null })

        // if (data.message?.length < 25) return this.setState({ contactFormMessageError: 'Please use at least 25 letters' });
        // else this.setState({ contactFormMessageError: null })

        data.lang = this.props.lang;
        data.url = typeof window !== 'undefined' ? window.location?.pathname : 'No Data';
        data.bookADemo = false
        data.page = this.props?.[0]?.match?.params?.alias?.replace(/-/g, ' ') || 'No Data';
        data.token = this.props.recaptchaToken
        data.isMobile = this.props.isMobile
        data.osMobileNameUA = this.props.osMobileNameUA
        data.browserName = this.props.browserName
        data.browserNameUA = this.props.browserNameUA

        this.setState(
          {
            loading2: true,
          },
          () => {

            fetch(API_ENDPOINT + "/data/contact/new", {
              method: "POST",
              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify({ data }),
            })
              .then(parseJSON)
              .then(({ result, status }) => {
                if (status === 429) {
                  this.setState({
                    solutionsStatus: true,
                    loading2: false,
                  });
                }
                if (result.error) {
                  this.setState({ error: result.error });
                } else {
                  this.props.bookDemo(false);

                  const path = "thank-you";
                  this.props[0].history.push(`/${path}`);
                }
              });
          }
        );
      }
    })
    this.props.updateToken();
  }


  sendMessage3 = (data) => {

    console.log('data sendmessage 3', data);


    this.props.setRecaptchaCallback(() => {

      if (data && data.checked && this.props.allowSubmit) {
        if (data.email && (data.email.toLowerCase().indexOf('@gmail.com') !== -1 || data.email.toLowerCase().indexOf('@live.com') !== -1 || data.email.toLowerCase().indexOf('@mailinator.com') !== -1 || data.email.toLowerCase().indexOf('@jollyfree.com') !== -1 || data.email.toLowerCase().indexOf('@gotgel.org') !== -1 || data.email.toLowerCase().indexOf('@yahoo.com') !== -1 || data.email.toLowerCase().indexOf('@hotmail.com') !== -1 || data.email.toLowerCase().indexOf('@business.com') !== -1 || data.email.toLowerCase().indexOf('@admin.com') !== -1 || data.email.toLowerCase().indexOf('@info.com') !== -1)) {
          return this.setState({ contactFormError: 'Please fill in your work email' });
        }
        else this.setState({ contactFormError: null })

        // if (data.message?.length < 25) return this.setState({ contactFormMessageError: 'Please use at least 25 letters' });
        // else this.setState({ contactFormMessageError: null })

        data.lang = this.props.lang;
        data.url = typeof window !== 'undefined' ? window.location?.pathname : 'No Data';
        data.bookADemo = false
        data.page = this.props?.[0]?.match?.params?.alias?.replace(/-/g, ' ') || 'No Data';
        data.token = this.props.recaptchaToken
        data.isMobile = this.props.isMobile
        data.osMobileNameUA = this.props.osMobileNameUA
        data.browserName = this.props.browserName
        data.browserNameUA = this.props.browserNameUA
        data.infoApp = this.state.infoApp
        data.createYouApp = true

        this.setState(
          {
            loadingInfoApp: true,
          },
          () => {

            fetch(API_ENDPOINT + "/data/contact/new", {
              method: "POST",
              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify({ data }),
            })
              .then(parseJSON)
              .then(({ result, status }) => {
                // console.log('aaaaaaa', result);

                if (status === 429) {
                  this.setState({
                    loadingInfoApp: false,
                  });
                }
                if (result.error) {
                  this.setState({ error: result.error });
                } else {
                  this.props.bookDemo(false);
                  setTimeout(() => {
                    this.setState({
                      sendMessage3Animation: true
                    })
                  }, 1500)

                  // const path = "thank-you";
                  // this.props[0].history.push(`/${path}`);
                }
              });
          }
        );
      }
    })
    this.props.updateToken();
  }



  get = () => {
    this.props.updateMeta(
      this.props.generateSeoTags(this.state, this.props.lang)
    );

    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            );
          }
        );
      });
    }

    for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
      this.props.loadDataWithQuery[i](
        window.fetch,
        this.props.lang,
        this.props[0].match
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            );
          }
        );
      });
    }


  };

  scroll = () => {
    if (this.state.isMobile) {
      this.formRef?.current?.scrollIntoView?.({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      })
    }
  }

  handleResize = () => {
    const isMobile = window?.innerWidth <= 991;
    this.setState({
      isMobile,
    })
  }

  componentDidMount() {
    this.get();

    if (this.props[0].location && !this.props[0].location.hash) {
      if (typeof window !== "undefined") {
        window.scrollTo(0, 0);
      }
    }

    this.updateWindowDimensions();
    window.addEventListener("scroll", this.listenToScroll);

    AOS.init({
      duration: 1000,
    });

    window.addEventListener('resize', this.handleResize);
    this.timeout1 = setTimeout(() => {
      this.handleResize();
    }, 100)
  }

  componentWillUnmount() {
    if (this.timeout1) clearTimeout(this.timeout1);
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps[0].location.pathname != this.props[0].location.pathname) {
      this.get();

      if (this.props[0].location.hash) {
        window.location.reload(false)
      }
    }

    if (prevProps[0].location.hash != this.props[0].location.hash && this.props[0].location.hash) {
      let id = this.props[0].location.hash.replace("#", "");
      if (document.getElementById(id)) {
        document.getElementById(id).scrollIntoView({ behavior: "smooth", block: 'start' });
        history.pushState("", document.title, window.location.pathname + window.location.search);
      }

    }

    if (prevProps[0].location.hash != this.props[0].location.hash && !this.props[0].location.hash) {
      window.scrollTo(0, 0)

    }

    if (this.state.pageData && JSON.stringify(this.state.pageData) !== JSON.stringify(prevState.pageData) && this.props[0].location && this.props[0].location.hash) {
      let id = this.props[0].location.hash.replace("#", "");
      if (document.getElementById(id)) {
        document.getElementById(id).scrollIntoView({ behavior: "smooth", block: 'start' });
        history.pushState("", document.title, window.location.pathname + window.location.search);
      }
    }

  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {

      const allowedTypes = ['image/jpeg', 'image/png', 'image/webp'];
      const maxSize = 10 * 1024 * 1024; // 20MB


      if (!allowedTypes.includes(file.type)) {
        this.setState({
          errorTypeLogo: true
        })
        return;
      }

      if (file.size > maxSize) {
        this.setState({
          errorTypeLogo: true
        });
        return;
      }

      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;

        this.setState((prevState) => {
          let infoApp = { ...prevState.infoApp };
          const errorFields = { ...prevState.errorFields };

          infoApp.imageLogo = file.name;
          infoApp.imagePathLogo = base64String;

          errorFields.imageLogoError = false;

          return { infoApp, errorFields, errorTypeLogo: false };
        });

        console.log('Base64 String for Logo:', base64String.substring(22));
      };

      reader.readAsDataURL(file);

      console.log('Logo file name:', this.state.infoApp.imageLogo);
      console.log("File Path (fake path):", e.target.value);
    }
  };

  handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {

      const allowedTypes = ['image/jpeg', 'image/png', 'image/webp'];
      const maxSize = 10 * 1024 * 1024; // 20MB

      if (!allowedTypes.includes(file.type)) {
        this.setState({
          errorTypeImage: true
        })
        return;
      }

      if (file.size > maxSize) {
        this.setState({
          errorTypeImage: true
        });
        return;
      }

      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;

        this.setState((prevState) => {
          let infoApp = { ...prevState.infoApp };
          let errorFields = { ...prevState.errorFields };

          infoApp.imageName = file.name;
          infoApp.imagePath = base64String;

          errorFields.imagePathError = false;

          return { infoApp, errorFields, errorTypeImage: false };
        });

        console.log('Base64 String:', base64String.substring(22));
      };

      reader.readAsDataURL(file);

      console.log('Image file name:', this.state.infoApp.imageName);
      console.log("File Path (fake path):", e.target.value);
    }
  };



  handleInputChange = (e) => {
    let inputValue = e.target.value;

    if (inputValue.length > 0 && inputValue[0] !== '#') {
      inputValue = '#' + inputValue;
    }

    this.setState((prevState) => {
      let infoApp = { ...prevState.infoApp };
      let errorFields = { ...prevState.errorFields };

      infoApp.color1 = inputValue;

      errorFields.colorError1 = false;

      return { infoApp, errorFields };
    });
  };

  handleInputChange2 = (e) => {
    let inputValue = e.target.value;

    if (inputValue.length > 0 && inputValue[0] !== '#') {
      inputValue = '#' + inputValue;
    }

    this.setState((prevState) => {
      let infoApp = { ...prevState.infoApp };
      let errorFields = { ...prevState.errorFields };

      infoApp.color2 = inputValue;

      errorFields.colorError2 = false;

      return { infoApp, errorFields };
    });
  };

  handleClick = (e) => {

    e.preventDefault();

    const { infoApp, checkedText1, checkedText2, checkedText3, checkedText4 } = this.state;
    let errorFields = { ...this.state.errorFields };

    errorFields.imageLogoError = infoApp.imageLogo === '';
    errorFields.imagePathError = infoApp.imageName === '';

    errorFields.colorError = !infoApp.color1;

    errorFields.textButtonError1 = !checkedText1 && infoApp.textButton1 === '' ? true : false;
    // errorFields.textButtonError2 = !checkedText2 && infoApp.textButton2 === '' ? true : false;
    // errorFields.textButtonError3 = !checkedText3 && infoApp.textButton3 === '' ? true : false;
    // errorFields.textButtonError4 = !checkedText4 && infoApp.textButton4 === '' ? true : false;

    if (!errorFields.imageLogoError &&
      !errorFields.imagePathError &&
      !errorFields.colorError &&
      !errorFields.textButtonError1
    ) {

      this.setState({
        currentStep: this.state.currentStep + 1,
      }, () => {
        this.scroll();
      })

      // this.setState(prevState => ({
      //   currentStep: prevState.currentStep + 1,
      // }));

    } else {
      this.setState({ errorFields });
    }
  };

  backPress = () => {
    this.setState((prevState) => ({
      infoApp: {
        ...prevState.infoApp,
      },
      currentStep: prevState.currentStep - 1
    }));
    this.scroll();
  }


  render() {


    return (
      <div>
        {this.state.loadingInfoApp ? (
          <div
            className={
              this.state.loadingInfoApp ? "overlay overlay-active" : "overlay"
            }
          // onClick={() => {
          //   this.setState({
          //     loadingInfoApp: false,
          //   });
          // }}
          ></div>
        ) : null}


        <div
          className={
            // this.props.openModalBookDemo
            this.state.loadingInfoApp
              ? "contact-popup open"
              : "contact-popup"
          }
        >
          {/* <span
            className="close-icon"
            onClick={() => {
              this.props.bookDemo(false);
            }}
          >
            x
          </span> */}

          {
            this.state.loadingInfoApp && this.state.sendMessage3Animation ?
              // <div style={{ background: 'red', width: 600, height: 600 }}>
              <Player
                ref={this.player}
                autoplay={true}
                onEvent={(event) => {
                  if (event == 'complete') {
                    this.setState({
                      loadingInfoApp: false,

                    }, () => {
                      const path = "thank-you";
                      this.props[0].history.push(`/${path}`);
                    })
                  }
                }}
                //keepLastFrame={true}
                //loop={false}
                controls={true}
                src="https://assets4.lottiefiles.com/packages/lf20_oaw8d1yt.json"
                style={{ height: '300px', width: '300px' }}
              ></Player>
              // </div>
              :
              null

          }

          {
            this.state.loadingInfoApp && !this.state.sendMessage3Animation ?
              // <div style={{ background: 'red', width: 600, height: 600 }}>
              <Player
                ref={this.player}
                autoplay={true}
                onEvent={(event) => {
                  if (event == 'complete') {
                    // this.setState({
                    //   loadingInfoApp: false,

                    // }, () => {
                    //   // const path = "thank-you";
                    //   // this.props[0].history.push(`/${path}`);
                    // })
                  }
                }}
                //keepLastFrame={true}
                loop={true}
                controls={true}
                src="https://lottie.host/8d5d9898-5507-4e9e-b545-869474e39e3d/Lfdek3e3hG.json"
                style={{ height: '300px', width: '300px' }}
              ></Player>
              // </div>
              :
              null

          }


          {/* {!this.state.loading3 && !this.state.supportStatus ? (
            <SupportForm initialValues={{ dob: '1' }} onSubmit={this.sendMessage2} lang={this.props.lang} />
          )
            : null} */}
          {/* 
          {this.state.loading3 ? (
            <Player
              ref={this.player}
              autoplay={true}
              onEvent={(event) => {
                if (event == "complete") {
                  this.setState({
                    supportForm: false,
                    loading3: false,
                  });
                }
              }}
              //keepLastFrame={true}
              //loop={false}
              controls={true}
              src="https://assets4.lottiefiles.com/packages/lf20_oaw8d1yt.json"
              style={{ height: "300px", width: "300px" }}
            ></Player>
          ) : null} */}
          {/* {this.state.supportStatus && (
            <h2 className="status-popup support-status">
              {"Please, try again later!".translate(this.props.lang)}
            </h2>
          )} */}
        </div>
        {
          this.state.pageData ? (
            <div>
              {this.state.pageData.section1 ? (
                <section
                  className={`sectionTextBigimg ${this.state.pageData &&
                    this.state.pageData.section[0] &&
                    this.state.pageData.section[0].backgroundImage
                    ? ""
                    : "sectionTextBigImgNoBg curoflowBlack"
                    }`}
                >


                  {this.state.pageData &&
                    this.state.pageData.section[0] &&
                    this.state.pageData.section[0].topImg
                    ? <Isvg
                      src={
                        API_ENDPOINT +
                        (this.state.pageData &&
                          this.state.pageData.section[0] &&
                          this.state.pageData.section[0].topImg)
                      } className="topImgTechnology"
                    /> : ""}



                  <div className="title">
                    {/* <h1 dangerouslySetInnerHTML={{__html: Object.translate(this.state.pageData && this.state.pageData.section[0], 'title', this.props.lang)}}></h1> */}
                    <h1>
                      {this.state.pageData &&
                        this.state.pageData.section[0] &&
                        this.state.pageData.section[0].title[this.props.lang]}
                    </h1>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: Object.translate(
                          this.state.pageData && this.state.pageData.section[0],
                          "shortDescription",
                          this.props.lang
                        ),
                      }}
                    ></div>
                  </div>
                  <div className="container-curoflow">
                    {this.state.pageData &&
                      this.state.pageData.section[0] &&
                      this.state.pageData.section[0].backgroundImage ? (
                      <Img src={API_ENDPOINT + (this.state.pageData?.section?.[0]?.backgroundImage?.[this.props.lang])} alt={(this.state.pageData?.section?.[0]?.backgroundImage?.[this.props.lang]?.replace('/uploads/', '')?.replace(/_/g, ' ')?.slice(0, -4))}
                      />
                    ) : null}
                  </div>
                  <img
                    src={CuroflowIcon}
                    className={`${this.state.pageData &&
                      this.state.pageData.backgroundImagePositionRight
                      ? "rightSideOurSolutons"
                      : " "
                      } ${this.state.pageData &&
                        this.state.pageData.backgroundImagePositionLeft
                        ? "leftSideOurSolutons"
                        : " "
                      }`}
                    alt="Curoflow Digital Telemedicine Platform Icon"
                  />
                </section>
              ) : null}




              {this.state.pageData.section2 ? (
                <section
                  className={`curoflow-section curoflow-paddiing curoflow-headings ${this.state.pageData &&
                    this.state.pageData.section[1] &&
                    this.state.pageData.section[1].item[0] &&
                    this.state.pageData.section[1].item[0].backgroundLightGrey
                    ? "curoflow-bg-lightgery"
                    : this.state.pageData &&
                      this.state.pageData.section[1] &&
                      this.state.pageData.section[1].item[0] &&
                      this.state.pageData.section[1].item[0].backgroundGrey
                      ? "curoflow-bg-grey"
                      : ""
                    }`}
                >
                  <div className="container-curoflow column-reverse">
                    <div className="col-curoflow-lg-6">
                      <h2>
                        {this.state.pageData &&
                          this.state.pageData.section[1] &&
                          this.state.pageData.section[1].item[0]?.title[
                          this.props.lang
                          ]}{" "}
                      </h2>
                      <div className="content-ourS"
                        dangerouslySetInnerHTML={{
                          __html: Object.translate(
                            this.state.pageData &&
                            this.state.pageData.section[1] &&
                            this.state.pageData.section[1].item[0],
                            "shortDescription",
                            this.props.lang
                          ),
                        }}
                      ></div>
                    </div>
                    <div className="col-curoflow-lg-6">
                      <Img
                        src={
                          API_ENDPOINT +
                          (this.state.pageData &&
                            this.state.pageData.section[1] &&
                            this.state.pageData.section[1].item[0] &&
                            this.state.pageData.section[1].item[0].image?.[this.props.lang])
                        }
                        alt={
                          this.state.pageData &&
                          this.state.pageData.section[1] &&
                          this.state.pageData.section[1].item[0] &&
                          this.state.pageData.section[1].item[0].image
                            ?.[this.props.lang]?.replace("/uploads/", "")
                            .replace("_", " ")
                            .slice(0, -4)
                        }
                      />

                    </div>
                  </div>
                </section>
              ) : null}








              {this.state.pageData.section10 && this.props.lang === 'en' ? (
                <section
                  className={`curoflow-section curoflow-paddiing curoflow-headings curoflow-newT`}
                >
                  <div className="container-curoflow">
                    <div className="col-curoflow-lg-6">
                      <a href={`${SITE_URL}/en/integrations/Semble-practice-management-system`} >
                        <Img
                          src={
                            API_ENDPOINT +
                            (this.state.pageData &&
                              this.state.pageData.section[9] &&
                              this.state.pageData.section[9].item[0] &&
                              this.state.pageData.section[9].item[0].image)
                          }
                          alt={
                            this.state.pageData &&
                            this.state.pageData.section[1] &&
                            this.state.pageData.section[1].item[0] &&
                            this.state.pageData.section[1].item[0].image
                              ?.[this.props.lang]?.replace("/uploads/", "")
                              .replace("_", " ")
                              .slice(0, -4)
                          }
                        />
                      </a>

                    </div>
                    <div className="col-curoflow-lg-6">
                      <h2>
                        {this.state.pageData &&
                          this.state.pageData.section[9] &&
                          this.state.pageData.section[9].item[0]?.title[
                          this.props.lang
                          ]}{" "}
                      </h2>
                      <div className="content-ourS"
                        dangerouslySetInnerHTML={{
                          __html: Object.translate(
                            this.state.pageData &&
                            this.state.pageData.section[9] &&
                            this.state.pageData.section[9].item[0],
                            "shortDescription",
                            this.props.lang
                          ),
                        }}
                      ></div>
                      <a href={this.state.pageData &&
                        this.state.pageData.section[9] &&
                        this.state.pageData.section[9].item[0]?.linkBtn[
                        this.props.lang
                        ]}>
                        <button>{this.state.pageData &&
                          this.state.pageData.section[9] &&
                          this.state.pageData.section[9].item[0]?.textBtn[
                          this.props.lang
                          ]}</button>


                      </a>
                    </div>

                  </div>
                </section>
              ) : null}


























              {this.state.pageData.section3 ? (
                <section className={`curoflow-section curoflow-margin-bottom ${this.state.pageData &&
                  this.state.pageData.section[2] &&
                  this.state.pageData.section[2].item[0] &&
                  this.state.pageData.section[2].item[0].backgroundLightGrey
                  ? "curoflow-bg-lightgery"
                  : this.state.pageData &&
                    this.state.pageData.section[2] &&
                    this.state.pageData.section[2].item[0] &&
                    this.state.pageData.section[2].item[0].backgroundGrey
                    ? "curoflow-bg-grey"
                    : ""
                  }`} id="managementCases">
                  <div className="container-curoflow">
                    <div className="col-curoflow-lg-6 ">
                      <Img className="margin-bottom2"
                        src={
                          API_ENDPOINT +
                          (this.state.pageData &&
                            this.state.pageData.section[2] &&
                            this.state.pageData.section[2].item[0] &&
                            this.state.pageData.section[2].item[0].image)
                        }

                        alt={
                          this.state.pageData &&
                          this.state.pageData.section[2] &&
                          this.state.pageData.section[2].item[0] &&
                          this.state.pageData.section[2].item[0].image
                            ?.replace("/uploads/", "")
                            .replace("_", " ")
                            .slice(0, -4)
                        }
                      />
                    </div>
                    <div className="col-curoflow-lg-6">
                      <h2>
                        {this.state.pageData &&
                          this.state.pageData.section[2] &&
                          this.state.pageData.section[2].item[0]?.title[
                          this.props.lang
                          ]}{" "}
                      </h2>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: Object.translate(
                            this.state.pageData &&
                            this.state.pageData.section[2] &&
                            this.state.pageData.section[2].item[0],
                            "shortDescription",
                            this.props.lang
                          ),
                        }}
                      ></p>
                    </div>
                  </div>
                </section>
              ) : null}


              {this.state.pageData.section8 ? (
                <section className="sectionPatinent">
                  <div className="container-curoflow column-reverse">
                    <div className="col-curoflow-lg-6">
                      <h2>
                        {this.state.pageData &&
                          this.state.pageData.section[7] &&
                          this.state.pageData.section[7].item[0]?.title[
                          this.props.lang
                          ]}
                      </h2>
                      <div className="content-ourS"
                        dangerouslySetInnerHTML={{
                          __html: Object.translate(
                            this.state.pageData &&
                            this.state.pageData.section[7] &&
                            this.state.pageData.section[7].item[0],
                            "shortDescription",
                            this.props.lang
                          ),
                        }}
                      ></div>
                    </div>
                    <div className="col-curoflow-lg-6">
                      <Img
                        src={
                          API_ENDPOINT +
                          (this.state.pageData &&
                            this.state.pageData.section[7] &&
                            this.state.pageData.section[7].item[0] &&
                            this.state.pageData.section[7].item[0].image)
                        }
                        alt={
                          this.state.pageData &&
                          this.state.pageData.section[7] &&
                          this.state.pageData.section[7].item[0] &&
                          this.state.pageData.section[7].item[0].image
                            ?.replace("/uploads/", "")
                            .replace("_", " ")
                            .slice(0, -4)
                        }
                      />

                    </div>
                  </div>
                </section>
              ) : null}



              {this.state.pageData.section9 ? (
                <section className="sectionPatinent">
                  <div className="container-curoflow">
                    <div className="col-curoflow-lg-6">
                      <Img
                        src={
                          API_ENDPOINT +
                          (this.state.pageData &&
                            this.state.pageData.section[8] &&
                            this.state.pageData.section[8].item[0] &&
                            this.state.pageData.section[8].item[0].image)
                        }
                        alt={
                          this.state.pageData &&
                          this.state.pageData.section[8] &&
                          this.state.pageData.section[8].item[0] &&
                          this.state.pageData.section[8].item[0].image
                            ?.replace("/uploads/", "")
                            .replace("_", " ")
                            .slice(0, -4)
                        }
                      />

                    </div>
                    <div className="col-curoflow-lg-6">
                      <h2>
                        {this.state.pageData &&
                          this.state.pageData.section[8] &&
                          this.state.pageData.section[8].item[0]?.title[
                          this.props.lang
                          ]}
                      </h2>
                      <div className="content-ourS"
                        dangerouslySetInnerHTML={{
                          __html: Object.translate(
                            this.state.pageData &&
                            this.state.pageData.section[8] &&
                            this.state.pageData.section[8].item[0],
                            "shortDescription",
                            this.props.lang
                          ),
                        }}
                      ></div>

                    </div>
                  </div>
                </section>
              ) : null}

              {this.state.pageData.section4 ? (
                <section className="curoflow-section-info">
                  <div className="container-curoflow">
                    <div className="col-curoflow-lg-4 bg-white">
                      <div className="info-box">
                        <Isvg
                          src={
                            API_ENDPOINT +
                            (this.state.pageData &&
                              this.state.pageData.section[3] &&
                              this.state.pageData.section[3].item[0] &&
                              this.state.pageData.section[3].item[0].image)
                          }
                        />
                        <h4 className="black-title">
                          {this.state.pageData &&
                            this.state.pageData.section[3] &&
                            this.state.pageData.section[3].item[0]?.title[
                            this.props.lang
                            ]}{" "}
                        </h4>
                        <div
                          className="black-text"
                          dangerouslySetInnerHTML={{
                            __html: Object.translate(
                              this.state.pageData &&
                              this.state.pageData.section[3] &&
                              this.state.pageData.section[3].item[0],
                              "shortDescription",
                              this.props.lang
                            ),
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="col-curoflow-lg-4 bg-white">
                      <div className="info-box">
                        <Isvg
                          src={
                            API_ENDPOINT +
                            (this.state.pageData &&
                              this.state.pageData.section[3] &&
                              this.state.pageData.section[3].item[1] &&
                              this.state.pageData.section[3].item[1].image)
                          }
                        />
                        <h4 className="black-title">
                          {this.state.pageData &&
                            this.state.pageData.section[3] &&
                            this.state.pageData.section[3].item[1]?.title[
                            this.props.lang
                            ]}{" "}
                        </h4>
                        <div
                          className="black-text"
                          dangerouslySetInnerHTML={{
                            __html: Object.translate(
                              this.state.pageData &&
                              this.state.pageData.section[3] &&
                              this.state.pageData.section[3].item[1],
                              "shortDescription",
                              this.props.lang
                            ),
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="col-curoflow-lg-4 bg-white">
                      <div className="info-box">
                        <Isvg
                          src={
                            API_ENDPOINT +
                            (this.state.pageData &&
                              this.state.pageData.section[3] &&
                              this.state.pageData.section[3].item[2] &&
                              this.state.pageData.section[3].item[2].image)
                          }
                        />
                        <h4 className="black-title">
                          {this.state.pageData &&
                            this.state.pageData.section[3] &&
                            this.state.pageData.section[3].item[2]?.title[
                            this.props.lang
                            ]}{" "}
                        </h4>
                        <div
                          className="black-text"
                          dangerouslySetInnerHTML={{
                            __html: Object.translate(
                              this.state.pageData &&
                              this.state.pageData.section[3] &&
                              this.state.pageData.section[3].item[2],
                              "shortDescription",
                              this.props.lang
                            ),
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </section>
              ) : null}










              {this.state.pageData.section5 ? (
                <div id={"section4"} className="curoflow-margin-top">
                  <section className="curoflow-section-info curoflow-section-box grey-c patientContent">
                    <div className="sectionTextBigimg sectionText">
                      <div className="title">
                        <h1
                          dangerouslySetInnerHTML={{
                            __html: Object.translate(
                              this.state.pageData &&
                              this.state.pageData.section[4] &&
                              this.state.pageData.section[4].item[0],
                              "title",
                              this.props.lang
                            ),
                          }}
                        ></h1>
                        <h4
                          dangerouslySetInnerHTML={{
                            __html: Object.translate(
                              this.state.pageData &&
                              this.state.pageData.section[4] &&
                              this.state.pageData.section[4].item[0],
                              "shortDescription",
                              this.props.lang
                            ),
                          }}
                        ></h4>
                      </div>
                    </div>
                    <div className="margin-bottom-c patientContentC">
                      <div className="container-curoflow ">
                        <div className="col-curoflow-lg-6 ">
                          <Img
                            src={
                              API_ENDPOINT +
                              (this.state.pageData &&
                                this.state.pageData.section[4] &&
                                this.state.pageData.section[4].item[1] &&
                                this.state.pageData.section[4].item[1].image)
                            }
                            className="img-margin"
                            alt={
                              this.state.pageData &&
                              this.state.pageData.section[4] &&
                              this.state.pageData.section[4].item[1] &&
                              this.state.pageData.section[4].item[1].image
                                ?.replace("/uploads/", "")
                                .replace("_", " ")
                                .slice(0, -4)
                            }
                          />
                        </div>
                        <div className="col-curoflow-lg-6">
                          <h2>
                            {this.state.pageData &&
                              this.state.pageData.section[4] &&
                              this.state.pageData.section[4].item[1]?.title[
                              this.props.lang
                              ]}{" "}
                          </h2>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: Object.translate(
                                this.state.pageData &&
                                this.state.pageData.section[4] &&
                                this.state.pageData.section[4].item[1],
                                "shortDescription",
                                this.props.lang
                              ),
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                    <div className="margin-bottom-c1">
                      <div className="container-curoflow  ">
                        <div className="col-curoflow-lg-4 bg-white">
                          <div className="info-box">
                            <Isvg
                              src={
                                API_ENDPOINT +
                                (this.state.pageData &&
                                  this.state.pageData.section[4] &&
                                  this.state.pageData.section[4].item[2] &&
                                  this.state.pageData.section[4].item[2].image)
                              }
                            />
                            <h4 className="black-title">
                              {this.state.pageData &&
                                this.state.pageData.section[4] &&
                                this.state.pageData.section[4].item[2]?.title[
                                this.props.lang
                                ]}{" "}
                            </h4>
                            <div
                              className="black-text"
                              dangerouslySetInnerHTML={{
                                __html: Object.translate(
                                  this.state.pageData &&
                                  this.state.pageData.section[4] &&
                                  this.state.pageData.section[4].item[2],
                                  "shortDescription",
                                  this.props.lang
                                ),
                              }}
                            ></div>
                          </div>
                        </div>
                        <div className="col-curoflow-lg-4 bg-white">
                          <div className="info-box">
                            <Isvg
                              src={
                                API_ENDPOINT +
                                (this.state.pageData &&
                                  this.state.pageData.section[4] &&
                                  this.state.pageData.section[4].item[3] &&
                                  this.state.pageData.section[4].item[3].image)
                              }
                            />
                            <h4 className="black-title">
                              {this.state.pageData &&
                                this.state.pageData.section[4] &&
                                this.state.pageData.section[4].item[3]?.title[
                                this.props.lang
                                ]}{" "}
                            </h4>
                            <div
                              className="black-text"
                              dangerouslySetInnerHTML={{
                                __html: Object.translate(
                                  this.state.pageData &&
                                  this.state.pageData.section[4] &&
                                  this.state.pageData.section[4].item[3],
                                  "shortDescription",
                                  this.props.lang
                                ),
                              }}
                            ></div>
                          </div>
                        </div>
                        <div className="col-curoflow-lg-4 bg-white">
                          <div className="info-box">
                            <Isvg
                              src={
                                API_ENDPOINT +
                                (this.state.pageData &&
                                  this.state.pageData.section[4] &&
                                  this.state.pageData.section[4].item[4] &&
                                  this.state.pageData.section[4].item[4].image)
                              }
                            />
                            <h4 className="black-title">
                              {this.state.pageData &&
                                this.state.pageData.section[4] &&
                                this.state.pageData.section[4].item[4]?.title[
                                this.props.lang
                                ]}{" "}
                            </h4>
                            <div
                              className="black-text"
                              dangerouslySetInnerHTML={{
                                __html: Object.translate(
                                  this.state.pageData &&
                                  this.state.pageData.section[4] &&
                                  this.state.pageData.section[4].item[4],
                                  "shortDescription",
                                  this.props.lang
                                ),
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="container-curoflow">
                      <div className="col-curoflow-lg-4 bg-white">
                        <div className="info-box">
                          <Isvg
                            src={
                              API_ENDPOINT +
                              (this.state.pageData &&
                                this.state.pageData.section[4] &&
                                this.state.pageData.section[4].item[5] &&
                                this.state.pageData.section[4].item[5].image)
                            }
                          />
                          <h4 className="black-title">
                            {this.state.pageData &&
                              this.state.pageData.section[4] &&
                              this.state.pageData.section[4].item[5]?.title[
                              this.props.lang
                              ]}{" "}
                          </h4>
                          <div
                            className="black-text"
                            dangerouslySetInnerHTML={{
                              __html: Object.translate(
                                this.state.pageData &&
                                this.state.pageData.section[4] &&
                                this.state.pageData.section[4].item[5],
                                "shortDescription",
                                this.props.lang
                              ),
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="col-curoflow-lg-4 bg-white">
                        <div className="info-box">
                          <Isvg
                            src={
                              API_ENDPOINT +
                              (this.state.pageData &&
                                this.state.pageData.section[4] &&
                                this.state.pageData.section[4].item[6] &&
                                this.state.pageData.section[4].item[6].image)
                            }
                          />
                          <h4 className="black-title">
                            {this.state.pageData &&
                              this.state.pageData.section[4] &&
                              this.state.pageData.section[4].item[6]?.title[
                              this.props.lang
                              ]}{" "}
                          </h4>
                          <div
                            className="black-text"
                            dangerouslySetInnerHTML={{
                              __html: Object.translate(
                                this.state.pageData &&
                                this.state.pageData.section[4] &&
                                this.state.pageData.section[4].item[6],
                                "shortDescription",
                                this.props.lang
                              ),
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="col-curoflow-lg-4 bg-white">
                        <div className="info-box">
                          <Isvg
                            src={
                              API_ENDPOINT +
                              (this.state.pageData &&
                                this.state.pageData.section[4] &&
                                this.state.pageData.section[4].item[7] &&
                                this.state.pageData.section[4].item[7].image)
                            }
                          />
                          <h4 className="black-title">
                            {this.state.pageData &&
                              this.state.pageData.section[3] &&
                              this.state.pageData.section[4].item[7]?.title[
                              this.props.lang
                              ]}{" "}
                          </h4>
                          <div
                            className="black-text"
                            dangerouslySetInnerHTML={{
                              __html: Object.translate(
                                this.state.pageData &&
                                this.state.pageData.section[3] &&
                                this.state.pageData.section[4].item[7],
                                "shortDescription",
                                this.props.lang
                              ),
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}



              {this.state.pageData.section11 ? (
                <div id={"section9"} className="curoflow-margin-top white-2">
                  <section className="curoflow-section-info curoflow-section-box black-c patientContent">
                    <div className="container-curoflow ">
                      <div className="col-curoflow-lg-6 ">
                        <h2>
                          {this.state.pageData &&
                            this.state.pageData.section[10] &&
                            this.state.pageData.section[10].item[0]?.title[
                            this.props.lang
                            ]}
                        </h2>
                        <div
                          className=""
                          dangerouslySetInnerHTML={{
                            __html: Object.translate(
                              this.state.pageData &&
                              this.state.pageData.section[10] &&
                              this.state.pageData.section[10].item[0],
                              "shortDescription",
                              this.props.lang
                            ),
                          }}
                        ></div>

                      </div>
                      <div className="col-curoflow-lg-6">
                        <Img
                          src={
                            API_ENDPOINT +
                            (this.state.pageData &&
                              this.state.pageData.section[10] &&
                              this.state.pageData.section[10].item[1] &&
                              this.state.pageData.section[10].item[1].image)
                          }
                          className="img-margin"
                          alt={
                            this.state.pageData &&
                            this.state.pageData.section[10] &&
                            this.state.pageData.section[10].item[1] &&
                            this.state.pageData.section[10].item[1].image
                              ?.replace("/uploads/", "")?.replace(/_/g, ' ')?.slice(0, -4)
                          }
                        />
                      </div>
                    </div>

                    <div className="margin-bottom-c1 ">
                      <h5 className="bg-title-white-title">
                        {this.state.pageData &&
                          this.state.pageData.section[10] &&
                          this.state.pageData.section[10].item[2]?.title[
                          this.props.lang
                          ]}
                      </h5>
                      <div className="container-curoflow ">
                        <div className="col-curoflow-lg-3 bg-box-grey">
                          <div className="info-box">
                            <h5 className="bg-title-yellow">
                              {this.state.pageData &&
                                this.state.pageData.section[10] &&
                                this.state.pageData.section[10].item[3]?.title[
                                this.props.lang
                                ]}
                            </h5>
                            <div
                              className="bg-title-white"
                              dangerouslySetInnerHTML={{
                                __html: Object.translate(
                                  this.state.pageData &&
                                  this.state.pageData.section[10] &&
                                  this.state.pageData.section[10].item[4],
                                  "shortDescription",
                                  this.props.lang
                                ),
                              }}
                            ></div>
                          </div>
                        </div>
                        <div className="col-curoflow-lg-3 bg-box-grey">
                          <div className="info-box">
                            <h5 className="bg-title-yellow">
                              {this.state.pageData &&
                                this.state.pageData.section[10] &&
                                this.state.pageData.section[10].item[5]?.title[
                                this.props.lang
                                ]}
                            </h5>
                            <div
                              className="bg-title-white"
                              dangerouslySetInnerHTML={{
                                __html: Object.translate(
                                  this.state.pageData &&
                                  this.state.pageData.section[10] &&
                                  this.state.pageData.section[10].item[6],
                                  "shortDescription",
                                  this.props.lang
                                ),
                              }}
                            ></div>
                          </div>
                        </div>
                        <div className="col-curoflow-lg-3 bg-box-grey">
                          <div className="info-box">
                            <h5 className="bg-title-yellow">
                              {this.state.pageData &&
                                this.state.pageData.section[10] &&
                                this.state.pageData.section[10].item[7]?.title[
                                this.props.lang
                                ]}
                            </h5>
                            <div
                              className="bg-title-white"
                              dangerouslySetInnerHTML={{
                                __html: Object.translate(
                                  this.state.pageData &&
                                  this.state.pageData.section[10] &&
                                  this.state.pageData.section[10].item[8],
                                  "shortDescription",
                                  this.props.lang
                                ),
                              }}
                            ></div>
                          </div>
                        </div>
                        <div className="col-curoflow-lg-3 bg-box-grey">
                          <div className="info-box">
                            <h5 className="bg-title-yellow">
                              {this.state.pageData &&
                                this.state.pageData.section[10] &&
                                this.state.pageData.section[10].item[9]?.title[
                                this.props.lang
                                ]}
                            </h5>
                            <div
                              className="bg-title-white"
                              dangerouslySetInnerHTML={{
                                __html: Object.translate(
                                  this.state.pageData &&
                                  this.state.pageData.section[10] &&
                                  this.state.pageData.section[10].item[10],
                                  "shortDescription",
                                  this.props.lang
                                ),
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}


              {this.state.pageData.section12 ? (
                <section className=" white-4" id="sekcija1" style={{ marginTop: 0 }}>
                  <div ref={this.formRef} aria-hidden className="test" />
                  <div className="container-curoflow ">

                    <div className="col-curoflow-lg-6">
                      {
                        this.state.currentStep === 1 ?
                          <Img
                            src={
                              API_ENDPOINT +
                              (this.state.pageData &&
                                this.state.pageData.section[11] &&
                                this.state.pageData.section[11].item[0] &&
                                this.state.pageData.section[11].item[0].image[this.props.lang])
                            }
                            className="img-margin"
                            alt={
                              this.state.pageData &&
                              this.state.pageData.section[11] &&
                              this.state.pageData.section[11].item[0] &&
                              this.state.pageData.section[11].item[0].image[this.props.lang]
                                ?.replace("/uploads/", "")
                                .replace("_", " ")
                                .slice(0, -4)
                            }
                          />



                          : null
                      }

                      {
                        this.state.currentStep === 2 ?

                          <form className="formApp" onSubmit={(e) => {
                            e.preventDefault();
                          }}>

                            <div className="content-1">
                              <p className="title">{'Step 1. Attachments'.translate(this.props.lang)}</p>
                              <div className="inputContent inputContent1">
                                <div className="logotype">{'Logotype'.translate(this.props.lang)}</div>

                                <label htmlFor="fileInputLogo" className={this.state.errorFields.imageLogoError ? 'errorInfo custom-file-upload' : "custom-file-upload"}>
                                  {this.state.infoApp.imageLogo && this.state.infoApp.imageLogo.length > 5 ? this.state.infoApp.imageLogo.slice(0, 6) + '...' : this.state.infoApp.imageLogo === '' ? 'Attach logo'.translate(this.props.lang) : this.state.infoApp.imageLogo}
                                </label>
                                <input
                                  type="file"
                                  className="inputFile"
                                  style={{ display: "none" }}
                                  id="fileInputLogo"
                                  onChange={(e) => {
                                    const file = e.target.files[0];
                                    console.log(file)
                                    if (file) {
                                      this.handleLogoChange(e);
                                    }
                                  }}
                                />

                              </div>
                              {
                                this.state.errorTypeLogo ? <p className="uploadImageError">{'Upload a valid file format (JPG, PNG, or WebP) with a maximum size of 10MB'.translate(this.props.lang)}</p> : null
                              }
                              <div className="inputContent inputContent2">
                                <div className="textImg">{'Image'.translate(this.props.lang)}</div>
                                <label htmlFor="fileInputImage" className={this.state.errorFields.imagePathError ? 'errorInfo custom-file-upload' : "custom-file-upload"}>
                                  {this.state.infoApp.imageName && this.state.infoApp.imageName.length > 5 ? this.state.infoApp.imageName.slice(0, 6) + '...' : this.state.infoApp.imageName === '' ? 'Attach image'.translate(this.props.lang) : this.state.infoApp.imageName}
                                </label>
                                <input
                                  type="file"
                                  className="inputFile"
                                  style={{ display: "none" }}
                                  id="fileInputImage"
                                  onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (file) {
                                      this.handleFileChange(e);
                                    }
                                  }}
                                />

                              </div>
                              {


                                this.state.errorTypeImage ? <p className="uploadImageError">{'Upload a valid file format (JPG, PNG, or WebP) with a maximum size of 10MB'.translate(this.props.lang)}</p> : null
                              }
                            </div>





                            <div className="content-2">
                              <p className="title titleForm">{'Step 2. Colors'.translate(this.props.lang)}</p>
                              <div className="inputTextFileds">
                                <input type='text' className={this.state.errorFields.colorError ? 'errorInfo' : null} placeholder={"Hex code primary color".translate(this.props.lang)} value={this.state.infoApp.color1} onChange={(e) => this.handleInputChange(e)} />
                                <input type='text' placeholder={"Hex code secondary color".translate(this.props.lang)} value={this.state.infoApp.color2} onChange={(e) => this.handleInputChange2(e)} />
                                {/* {this.state.errorFields.colorError && <p className="error">{this.state.errorFields.colorError}</p>} */}
                              </div>
                            </div>



                            <div className="content-3">
                              <p className="title">{"Step 3. Buttons".translate(this.props.lang)}</p>
                              <div className="subtitle">{'How many buttons do you need?'.translate(this.props.lang)}</div>
                              <div className="chooseButtonPart1">
                                <div className="chooseButton chooseButton1">
                                  {/* <label for="id1" className={this.state.errorFields.textButton1 ? 'klasa' : this.state.checkedText1 ? null : 'noLabel'}></label>
                                <input type='checkbox' className="checkBox checkBox1" id="id1" onChange={(e) => {
                                  this.setState({
                                    checkedText1: !this.state.checkedText1
                                  })
                                }} /> */}
                                  <label>{'Button 1'.translate(this.props.lang)}</label>
                                  <input type='text' value={this.state.infoApp.textButton1} className={this.state.errorFields.textButtonError1 ? 'erorInfoTextBtn inputButtonText' : "inputButtonText"} placeholder={"Text on button 1".translate(this.props.lang)} onChange={(e) => {
                                    let infoApp = { ...this.state.infoApp };
                                    infoApp.textButton1 = e.target.value;
                                    this.setState({ infoApp });
                                  }} />

                                </div>
                                <div className="chooseButton chooseButton1">
                                  {/* <label for="id2" className={!this.state.checkedText2 ? 'noLabel' : null}></label> */}
                                  {/* <input type='checkbox' className="checkBox checkBox1" id='id2' onChange={(e) => {
                                  this.setState({
                                    checkedText2: !this.state.checkedText2
                                  })
                                }} /> */}
                                  <label>{'Button 2'.translate(this.props.lang)}</label>
                                  <input type='text' value={this.state.infoApp.textButton2} placeholder={"Text on button 2".translate(this.props.lang)} className={this.state.errorFields.textButtonError2 ? 'eroorInfo' : "inputButtonText"} onChange={(e) => {
                                    let infoApp = { ...this.state.infoApp };
                                    infoApp.textButton2 = e.target.value;
                                    this.setState({ infoApp });
                                  }} />
                                </div>
                              </div>




                              <div className="chooseButtonPart2">
                                <div className="chooseButton chooseButton1">
                                  {/* <label for="id3" className={!this.state.checkedText3 ? 'noLabel' : null}></label>
                                <input type='checkbox' id={'id3'} className="checkBox checkBox1" onChange={(e) => {
                                  this.setState({
                                    checkedText3: !this.state.checkedText3
                                  })
                                }} /> */}
                                  <label>{'Button 3'.translate(this.props.lang)}</label>
                                  <input type='text' value={this.state.infoApp.textButton3} placeholder={"Text on button 3".translate(this.props.lang)} className={this.state.errorFields.textButtonError3 ? 'eroorInfo' : "inputButtonText"} onChange={(e) => {
                                    let infoApp = { ...this.state.infoApp };
                                    infoApp.textButton3 = e.target.value;
                                    this.setState({ infoApp });
                                  }} />
                                </div>
                                <div className="chooseButton chooseButton1">
                                  {/* <label for="id4" className={!this.state.checkedText4 ? 'noLabel' : null}></label>
                                <input type='checkbox' className="checkBox checkBox1" id="id4" onChange={(e) => {
                                  this.setState({
                                    checkedText4: !this.state.checkedText4
                                  })
                                }} /> */}
                                  <label>{'Button 4'.translate(this.props.lang)}</label>
                                  <input type='text' value={this.state.infoApp.textButton4} placeholder={"Text on button 4".translate(this.props.lang)} className={this.state.errorFields.textButtonError4 ? 'eroorInfo' : "inputButtonText"} onChange={(e) => {
                                    let infoApp = { ...this.state.infoApp };
                                    infoApp.textButton4 = e.target.value;
                                    this.setState({ infoApp });
                                  }} />
                                </div>
                              </div>

                              <div className="buttonContent">
                                <button onClick={() => {
                                  this.setState({
                                    currentStep: this.state.currentStep - 1,
                                    infoApp: {
                                      imageLogo: '',
                                      imagePathLog: '',
                                      imageName: '',
                                      imagePath: '',
                                      color1: '',
                                      color2: '',
                                      textButton1: '',
                                      textButton2: '',
                                      textButton3: '',
                                      textButton4: ''
                                    }
                                  }, () => {
                                    this.scroll();
                                  })
                                }}>{'Back'.translate(this.props.lang)}</button>
                                <button onClick={(e) => { this.handleClick(e) }}>{'Next'.translate(this.props.lang)}</button>

                              </div>

                              <div>
                                {
                                  (this.state.errorFields.imageLogoError ||
                                    this.state.errorFields.imagePathError ||
                                    this.state.errorFields.colorError ||
                                    this.state.errorFields.textButtonError1) &&
                                  <p className="error">{'Fill in the required information and try again'.translate(this.props.lang)}</p>
                                }

                              </div>
                              {
                                this.state.error ?
                                  <p>
                                    {this.state.error}
                                  </p>
                                  : null
                              }
                            </div>
                          </form>

                          : null
                      }

                      {
                        this.state.currentStep === 3 ?
                          <ContactForm1
                            title={
                              null
                            }

                            text={
                              null
                            }
                            infoApp={this.state.infoApp}
                            contactFormError={this.state.contactFormError}
                            contactFormMessageError={this.state.contactFormMessageError}
                            class={"grey"}
                            justShowForm={true}
                            showTextContact={true}
                            onSubmit={(data) => {
                              console.log('data send message3 ', data);

                              this.sendMessage3(data)
                            }}
                            lang={this.props.lang}
                            initialValues={{
                              dob: '1'
                            }}
                            onBackPress={this.backPress}
                          />
                          : null
                      }


                    </div>

                    <div className="col-curoflow-lg-6 aligin-self">
                      <h2>
                        {this.state.pageData &&
                          this.state.pageData.section[11] &&
                          this.state.pageData.section[11].item[0]?.title[
                          this.props.lang
                          ]}
                      </h2>
                      <div
                        className=""
                        dangerouslySetInnerHTML={{
                          __html: Object.translate(
                            this.state.pageData &&
                            this.state.pageData.section[11] &&
                            this.state.pageData.section[11].item[0],
                            "shortDescription",
                            this.props.lang
                          ),
                        }}
                      ></div>
                      {
                        this.state.currentStep === 1 ?
                          <button onClick={() => {
                            this.setState({
                              currentStep: this.state.currentStep + 1
                            }, () => {
                              this.scroll();
                            })
                          }} className="createApp">{'Create your app'.translate(this.props.lang)}</button>
                          : null
                      }

                    </div>

                  </div>

                </section>

              ) : null}




              {this.state.pageData.section13 ? (
                <div id={"section9"} className="curoflow-margin-top">
                  <section className="white-5">
                    <div className="container-curoflow white-5-title">

                      <h1>
                        {this.state.pageData &&
                          this.state.pageData.section[12] &&
                          this.state.pageData.section[12].item[0]?.title[
                          this.props.lang
                          ]}
                      </h1>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: Object.translate(
                            this.state.pageData &&
                            this.state.pageData.section[12] &&
                            this.state.pageData.section[12].item[0],
                            "shortDescription",
                            this.props.lang
                          ),
                        }}
                      ></div>

                    </div>
                    <div className="container-curoflow white-5-content">

                      <div className="col-curoflow-lg-3 box-content">
                        <Isvg
                          src={
                            API_ENDPOINT +
                            (this.state.pageData &&
                              this.state.pageData.section[12] &&
                              this.state.pageData.section[12].item[1] &&
                              this.state.pageData.section[12].item[1].image)
                          }
                        />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: Object.translate(
                              this.state.pageData &&
                              this.state.pageData.section[12] &&
                              this.state.pageData.section[12].item[1],
                              "shortDescription",
                              this.props.lang
                            ),
                          }}
                        ></div>
                      </div>

                      <div className="col-curoflow-lg-3 box-content second">
                        <Isvg
                          src={
                            API_ENDPOINT +
                            (this.state.pageData &&
                              this.state.pageData.section[12] &&
                              this.state.pageData.section[12].item[2] &&
                              this.state.pageData.section[12].item[2].image)
                          }
                        />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: Object.translate(
                              this.state.pageData &&
                              this.state.pageData.section[12] &&
                              this.state.pageData.section[12].item[2],
                              "shortDescription",
                              this.props.lang
                            ),
                          }}
                        ></div>
                      </div>
                      <div className="col-curoflow-lg-3 box-content">
                        <Isvg
                          src={
                            API_ENDPOINT +
                            (this.state.pageData &&
                              this.state.pageData.section[12] &&
                              this.state.pageData.section[12].item[3] &&
                              this.state.pageData.section[12].item[3].image)
                          }
                        />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: Object.translate(
                              this.state.pageData &&
                              this.state.pageData.section[12] &&
                              this.state.pageData.section[12].item[3],
                              "shortDescription",
                              this.props.lang
                            ),
                          }}
                        ></div>
                      </div>

                    </div>
                    <div className="container-curoflow white-5-content">

                      <div className="col-curoflow-lg-3 box-content">
                        <Isvg
                          src={
                            API_ENDPOINT +
                            (this.state.pageData &&
                              this.state.pageData.section[12] &&
                              this.state.pageData.section[12].item[4] &&
                              this.state.pageData.section[12].item[4].image)
                          }
                        />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: Object.translate(
                              this.state.pageData &&
                              this.state.pageData.section[12] &&
                              this.state.pageData.section[12].item[4],
                              "shortDescription",
                              this.props.lang
                            ),
                          }}
                        ></div>
                      </div>

                      <div className="col-curoflow-lg-3 box-content">
                        <Isvg
                          src={
                            API_ENDPOINT +
                            (this.state.pageData &&
                              this.state.pageData.section[12] &&
                              this.state.pageData.section[12].item[5] &&
                              this.state.pageData.section[12].item[5].image)
                          }
                        />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: Object.translate(
                              this.state.pageData &&
                              this.state.pageData.section[12] &&
                              this.state.pageData.section[12].item[5],
                              "shortDescription",
                              this.props.lang
                            ),
                          }}
                        ></div>
                      </div>
                      <div className="col-curoflow-lg-3 box-content">
                        <Isvg
                          src={
                            API_ENDPOINT +
                            (this.state.pageData &&
                              this.state.pageData.section[12] &&
                              this.state.pageData.section[12].item[6] &&
                              this.state.pageData.section[12].item[6].image)
                          }
                        />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: Object.translate(
                              this.state.pageData &&
                              this.state.pageData.section[12] &&
                              this.state.pageData.section[12].item[6],
                              "shortDescription",
                              this.props.lang
                            ),
                          }}
                        ></div>
                      </div>

                    </div>


                  </section>
                </div>
              ) : null}

              {this.state.pageData.section14 ? (

                <section className="white-6">
                  <img src={AppImg} className='AppImg' />
                  <div className="container-curoflow white-6-title">
                    <h1>
                      {this.state.pageData &&
                        this.state.pageData.section[13] &&
                        this.state.pageData.section[13].item[0]?.title[
                        this.props.lang
                        ]}
                    </h1>
                    <div className="last-white-section"
                      dangerouslySetInnerHTML={{
                        __html: Object.translate(
                          this.state.pageData &&
                          this.state.pageData.section[13] &&
                          this.state.pageData.section[13].item[0],
                          "shortDescription",
                          this.props.lang
                        ),
                      }}
                    ></div>

                  </div>
                  <div className="container-curoflow white-6-content">

                    <div className="col-curoflow-lg-6 box-content">
                      <h5 className="bg-title-yellow">
                        {this.state.pageData &&
                          this.state.pageData.section[13] &&
                          this.state.pageData.section[13].item[1]?.title[
                          this.props.lang
                          ]}
                      </h5>
                      <div
                        className="bg-title-white"
                        dangerouslySetInnerHTML={{
                          __html: Object.translate(
                            this.state.pageData &&
                            this.state.pageData.section[13] &&
                            this.state.pageData.section[13].item[1],
                            "shortDescription",
                            this.props.lang
                          ),
                        }}
                      ></div>
                    </div>

                    <div className="col-curoflow-lg-6 box-content">
                      <h5 className="bg-title-yellow">
                        {this.state.pageData &&
                          this.state.pageData.section[13] &&
                          this.state.pageData.section[13].item[2]?.title[
                          this.props.lang
                          ]}
                      </h5>
                      <div
                        className="bg-title-white"
                        dangerouslySetInnerHTML={{
                          __html: Object.translate(
                            this.state.pageData &&
                            this.state.pageData.section[13] &&
                            this.state.pageData.section[13].item[2],
                            "shortDescription",
                            this.props.lang
                          ),
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="container-curoflow white-6-content">

                    <div className="col-curoflow-lg-6 box-content">
                      <h5 className="bg-title-yellow">
                        {this.state.pageData &&
                          this.state.pageData.section[13] &&
                          this.state.pageData.section[13].item[3]?.title[
                          this.props.lang
                          ]}
                      </h5>
                      <div
                        className="bg-title-white"
                        dangerouslySetInnerHTML={{
                          __html: Object.translate(
                            this.state.pageData &&
                            this.state.pageData.section[13] &&
                            this.state.pageData.section[13].item[3],
                            "shortDescription",
                            this.props.lang
                          ),
                        }}
                      ></div>
                    </div>
                    <div className="col-curoflow-lg-6 box-content">
                      <h5 className="bg-title-yellow">
                        {this.state.pageData &&
                          this.state.pageData.section[13] &&
                          this.state.pageData.section[13].item[4]?.title[
                          this.props.lang
                          ]}
                      </h5>
                      <div
                        className="bg-title-white"
                        dangerouslySetInnerHTML={{
                          __html: Object.translate(
                            this.state.pageData &&
                            this.state.pageData.section[13] &&
                            this.state.pageData.section[13].item[4],
                            "shortDescription",
                            this.props.lang
                          ),
                        }}
                      ></div>
                    </div>

                  </div>
                  <div className="container-curoflow white-6-content">

                    <div className="col-curoflow-lg-6 box-content">
                      <h5 className="bg-title-yellow">
                        {this.state.pageData &&
                          this.state.pageData.section[13] &&
                          this.state.pageData.section[13].item[5]?.title[
                          this.props.lang
                          ]}
                      </h5>
                      <div
                        className="bg-title-white"
                        dangerouslySetInnerHTML={{
                          __html: Object.translate(
                            this.state.pageData &&
                            this.state.pageData.section[13] &&
                            this.state.pageData.section[13].item[5],
                            "shortDescription",
                            this.props.lang
                          ),
                        }}
                      ></div>
                    </div>

                    <div className="col-curoflow-lg-6 box-content">
                      <h5 className="bg-title-yellow">
                        {this.state.pageData &&
                          this.state.pageData.section[13] &&
                          this.state.pageData.section[13].item[6]?.title[
                          this.props.lang
                          ]}
                      </h5>
                      <div
                        className="bg-title-white"
                        dangerouslySetInnerHTML={{
                          __html: Object.translate(
                            this.state.pageData &&
                            this.state.pageData.section[13] &&
                            this.state.pageData.section[13].item[6],
                            "shortDescription",
                            this.props.lang
                          ),
                        }}
                      ></div>
                    </div>
                  </div>


                </section>

              ) : null}






              {this.state.pageData.section6 ? <div></div> : null}
              <ContactForm
                title={
                  this.state.pageData &&
                  this.state.pageData.section[6] &&
                  this.state.pageData.section[6].item[0]?.title[this.props.lang]
                }

                text={
                  this.state.pageData &&
                    this.state.pageData.section[6] &&
                    this.state.pageData.section[6].item[0]
                    ? Object.translate(
                      this.state.pageData &&
                      this.state.pageData.section[6] &&
                      this.state.pageData.section[6].item[0],
                      "shortDescription",
                      this.props.lang
                    )
                    : null
                }
                contactFormError={this.state.contactFormError}
                contactFormMessageError={this.state.contactFormMessageError}
                class={"grey"}
                justShowForm={true}
                showTextContact={true}
                onSubmit={this.sendMessage2}
                lang={this.props.lang}
                initialValues={{
                  dob: '1'
                }}
              />
            </div>
          ) : null
        }
      </div >
    );
  }
}

export default Page(OurSolutions);
