export const homePage = {
  _id: "6470ac674d02dd1d7364385a",
  descriptionBelowFirstSlider: {
    se: "<p>Vi vill g&auml;rna h&ouml;ra om de utmaningar ni har i just er v&aring;rdverksamhet och se hur v&aring;r digitala v&aring;rdplattform kan effektivisera era processer, spara tid och ge era patienter en n&auml;rmare v&aring;rd. Vi ses g&auml;rna fysiskt i Stockholmsomr&aring;det eller s&aring; budar vi &ouml;ver en lunch och tar m&ouml;tet &ouml;ver video!</p>",
    en: "<p>Vi vill g&auml;rna h&ouml;ra om de utmaningar ni har i just er v&aring;rdverksamhet och se hur v&aring;r digitala v&aring;rdplattform kan effektivisera dina processer, spara tid och ge era patienter en n&auml;rmare v&aring;rd.Vi ses g&auml;rna fysiskt i Stockholmsomr&aring;det eller s&aring; budar vi &ouml;ver en lunch och tar m&ouml;tet &ouml;ver video. Vi bjuder!</p>",
  },
  tsCreated: 1709543098,
  firstSliderItems: [
    {
      title: {
        se: "Nå den fulla potentialen av digifysisk vård med en plattform som ger er flow",
        en: "Unlock the power of digital healthcare with a platform that flows ",
      },
      description: {
        se: "<h5>M&ouml;t framtidens sjukv&aring;rd med Curoflow - en helt&auml;ckande digital v&aring;rdplattform f&ouml;r alla typer av v&aring;rdgivare</h5>",
        en: "<h5>Say hello to the future of healthcare with Curoflow - the comprehensive digital healthcare and telemedicine platform</h5>",
      },
      link: "",
      imageOrVideo:
        "/uploads/Nurse_working_with_Curoflow_telemedicine_platform_at_primary_care_clinic.jpg",
      edit: 0,
      imageOrVideoMobile:
        "/uploads/Digital_healthcare_through_Curoflow_white_label_telemedicine_platform.jpg",
    },
    {
      title: {
        se: "Öka bekvämligheten genom att låta patienter nå er mottagning online",
        en: "Increase convenience by enabling patients to reach your clinic online",
      },
      description: {
        se: "<h5>Tillg&auml;nglig v&aring;rd f&ouml;r era patienter med chatt, video, medicinska formul&auml;r, bokningsbara kalendrar, recept, resultat och s&aring; mycket mer. Ni best&auml;mmer!</h5>",
        en: "<h5>Easy access for your patients with chat, video, medical forms, bookable calendars, prescriptions, results and so much more. You decide!</h5>",
      },
      imageOrVideo:
        "/uploads/Digital_healthcare_through_Curoflow_telemedicine_platform.jpg",
      link: "var-plattform/digital-patientportal-i-vardplattformen",
      edit: 1,
      imageOrVideoMobile:
        "/uploads/Digital_healthcare_through_the_Curoflow_telemedicine_platform.jpg",
    },
    {
      title: {
        se: "Curoflow digital vårdplattform är valet för GDPR-efterlevnad",
        en: "Curoflow telemedicine platform is the choice for GDPR-compliance",
      },
      description: {
        se: "<h5>Plattformen &auml;r CE-m&auml;rkt enligt MDR och vi behandlar all data inom EU f&ouml;r strikt efterlevnad av GDPR</h5>",
        en: "<h5>The platform is CE-marked according to the MDR and we process all data within the EU for strict compliance with the GDPR</h5>",
      },
      link: "gdpr-efterlevnad-i-Curoflow-digital-vardplattform",
      imageOrVideo:
        "/uploads/Providing_digital_healthcare_through_the_GDPR-secure_telemedicine_platform.jpg",
      edit: 2,
      imageOrVideoMobile:
        "/uploads/Providing_digital_healthcare_through_a_GDPR-secure_telemedicine_software.jpg",
    },
    {
      title: {
        se: "Vill ni ha fler och mer nöjda patienter på er vårdcentral?",
        en: "Do you want more and happier patients at your primary care clinic?",
      },
      description: {
        se: "<h5>Curoflow v&aring;rdplattform revolutionerar prim&auml;rv&aring;rden med digitala l&ouml;sningar som hyllas av b&aring;de v&aring;rdpersonal och patienter</h5>",
        en: "<h5>The Curoflow telemedicine platform is revolutionizing primary care with digital solutions praised by both healthcare professionals and patients</h5>",
      },
      imageOrVideo:
        "/uploads/Nurse_using_Curoflow_telemedicine_platform_at_primary_care_clinic.jpg",
      link: "vi-hjalper/digital-vardplattform-for-primarvard",
      edit: 3,
      imageOrVideoMobile:
        "/uploads/Nurse_using_the_Curoflow_telemedicine_software_at_primary_care_clinic.jpg",
    },
    {
      title: {
        se: "En digital vårdplattform för fertilitetsvården",
        en: "A telemedicine platform for fertility and reproductive healthcare",
      },
      description: {
        se: "<h5>Enkel kommunikation, minskade ledtider och kalendrar med automatiska p&aring;minnelser - Curoflow &ouml;kar chanserna f&ouml;r lyckade fertilitetsbehandlingar</h5>",
        en: "<h5>Easy communication, reduced lead times and calendars with automatic reminders - Curoflow increases the chances of successful fertility treatments</h5>",
      },
      imageOrVideo:
        "/uploads/Nurse_treating_patients_online_through_Curoflow_telemedicine_platform.jpg",
      link: "vi-hjalper/digital-vardplattform-for-fertilitetsvard",
      edit: 4,
      imageOrVideoMobile: "/uploads/Nurse_treating_patients_online_through_the_Curoflow_telemedicine_platform.jpg",
    },
  ],
  secondSliderITems: [
    {
      title: {
        en: "Digital entry points for your patients",
        se: "Digitala ingångar för era patienter",
      },
      edit: 0,
      imageOrVideo: {
        en: "/uploads/Curoflow_telemedicine_platform_patient_portal_with_web_interface_and_app.webp",
        se: "/uploads/Curoflow_digital_vårdplattform_patient_portal_app.webp"
      },

      link: "var-plattform/digital-patientportal-i-vardplattformen",
      tabname: { se: "Digital patientportal", en: "Digital patient portal" },
      description: {
        se: "<p>Genom Curoflow kan patienter smidigt n&aring; er mottagning online f&ouml;r att s&ouml;ka v&aring;rd. Ni har friheten att v&auml;lja patienternas digitala ing&aring;ngar, s&aring;som chattmeddelanden, tidsbokning av digitala eller fysiska bes&ouml;k och m&ouml;jligheten att styra v&auml;xelsamtal digitalt. Dessutom kan ni enkelt boka in och kalla patienter till nya m&ouml;ten, s&aring;v&auml;l digitala som fysiska.</p>",
        en: "<p>Through Curoflow, patients can effortlessly connect with your practice online and seek medical care. You have the freedom to select their digital access points, like chat-based messaging, scheduling digital or in-person visits, and whether they can submit their own care requests. Moreover, you can conveniently create new appointments, including follow-up visits conducted online or in-person.</p>",
      },
      btnText: {
        se: "Upptäck er digitala partientportal",
        en: "Discover your digital patient portal",
      },
    },
    {
      title: {
        en: "Self-service & automation",
        se: "Självbetjäning & automation",
      },
      edit: 1,
      imageOrVideo: {
        en: "/uploads/Patient_uses_self-service_functions_in_Curoflow_telemedicine_platform.webp",
        se: "/uploads/Patient_fyller_i_dynamiska_formulär_i_Curoflow_plattform.webp"
      },
      link: "var-plattform/digital-patientportal-i-vardplattformen#section4",
      tabname: {
        se: "Självbetjäning & automation",
        en: "Self-service & automation",
      },
      description: {
        se: "<p>Curoflow minskar b&ouml;rdan f&ouml;r v&aring;rdpersonal genom att automatisera processer och m&ouml;jligg&ouml;ra f&ouml;r patienter att sj&auml;lva g&ouml;ra bokningar, skapa underlag f&ouml;r status och anamnes och utf&ouml;ra andra administrativt tunga uppgifter. Genom integrationer med journalsystem &ouml;verf&ouml;rs kalenderbokningar och journalanteckningar enkelt mellan systemen.</p>",
        en: "<p>Curoflow eases the workload of healthcare personnel by automating tasks and empowering patients to make appointments, complete their medical history, and handle other activities by themselves. Curoflow seamlessly integrates with EHR systems, automatically transferring calendar bookings and journal notes between the systems.</p>",
      },
      btnText: {
        se: "Upptäck självbetjäning & automation",
        en: "Discover self-service & automation",
      },
    },
    {
      title: {
        en: "White-label & customization",
        se: "White label & anpassning",
      },
      edit: 2,
      imageOrVideo: {
        en: "/uploads/White_label_telemedicine_software_for_your_brand_apps_and_desktop.webp",
        se: "/uploads/White_label_digital_vårdplattform_med_ditt_varumärke_appar_och_desktop.webp"
      },
      link: "var-plattform/white-label-och-anpassningsbar-vardplattform",
      tabname: {
        se: "White label & anpassning",
        en: "White-label & customization",
      },
      description: {
        se: "<p>Curoflow v&aring;rdplattform &auml;r skalbar och anpassningsbar f&ouml;r olika typer av v&aring;rdorganisationer, fr&aring;n sm&aring; kliniker till hela kommuner, regioner och st&ouml;rre f&ouml;retag. Plattformen erbjuds som white label via webb och app och det &auml;r endast ert varum&auml;rke och grafiska profil som &auml;r synligt f&ouml;r era patienter.&nbsp;</p>",
        en: "<p>The Curoflow telemedicine platform is scalable and adaptable to different types of healthcare organizations, ranging from small clinics to entire municipalities and large corporations. The platform is a white-label product, ensuring that only your brand and graphic profile are visible to your patients.</p>",
      },
      btnText: {
        se: "Upptäck white label & anpassning",
        en: "Discover white-label & customization",
      },
    },
    {
      title: { en: "Chat & video meetings", se: "Chatt & videomöten" },
      edit: 3,
      imageOrVideo:
        "/uploads/Doctor_in_GDPR-secure_video_meeting_regarding_patients.jpg",
      link: "var-plattform/chatt-och-videomoten-i-vardplattformen",
      tabname: { se: "Chatt & videomöten", en: "Chat & video meetings" },
      description: {
        se: "<p>Med Curoflow kan ni enkelt kommunicera med patienter, kollegor och externa parter n&auml;r och var det passar dem och er. V&aring;r videotj&auml;nst &auml;r egenutvecklad f&ouml;r s&auml;kra v&aring;rdm&ouml;ten som f&ouml;ljer GDPR och ni kan enkelt bjuda in tredje part utan n&aring;gra krav p&aring; mjukvaruinstallation eller nedladdningar. I den asynkrona chatten kan ni &auml;ven dela dynamiska och intelligenta fr&aring;geformul&auml;r, bilder och annan relevant information som r&ouml;r v&aring;rd&auml;renden.</p>",
        en: "<p>Curoflow enables you to conveniently connect with your patients and other parties anytime and anywhere it's convenient for them. Our video service is designed for secure healthcare meetings that adhere to GDPR compliance. You can effortlessly invite third parties without the need for software installation. In the asynchronous chat, you can share dynamic questionnaires, images, and other pertinent information related to medical cases.</p>",
      },
      btnText: {
        se: "Upptäck chatt & videomöten",
        en: "Discover chat & video meetings",
      },
    },
    {
      title: {
        en: "Patient monitoring with data",
        se: "Kronikermonitorering med data",
      },
      edit: 4,
      imageOrVideo: {
        en: "/uploads/Patient_health_tracker_in_app_for_remote_patient_monitoring_of_chronic_conditions.webp",
        se: "/uploads/Monitorering_i_Curoflow_av_patienter_med_kroniska_tillstånd_i_dagbok.webp"
      },
      link: "var-plattform/kronikermonitorering-med-data-genom-vardplattformen",
      tabname: {
        se: "Kronikermonitorering med data",
        en: "Patient monitoring with data",
      },
      description: {
        se: "<p>Curoflows dagboksfunktionalitet &auml;r unik och m&ouml;jligg&ouml;r monitorering av patienter med kroniska tillst&aring;nd. Patienterna f&ouml;ljs upp under angivna intervaller vilket ger er strukturerad information &ouml;ver tid. Informationen omvandlas till data, statistik och beslutsst&ouml;d. Patienter upplever de automatiserade funktionerna som att v&aring;rdgivaren ser och h&ouml;r dem p&aring; regelbunden basis.</p>",
        en: "<p>Curoflow's calendar with a symptom tracker enables remote monitoring of patients with chronic conditions. Patients can log structured health data, which converts into valuable statistics for the healthcare provider. The calendar also offers customizable notifications, making it easier for patients to track their progress in longer care processes.</p>",
      },
      btnText: {
        se: "Upptäck kronikermonitorering med data",
        en: "Discover patient monitoring with data",
      },
    },
    {
      tabname: {
        en: "Consultations & referrals",
        se: "Konsultationer & remittering",
      },
      title: {
        en: "Enhancing consultations & referrals",
        se: "Konsultationer & remittering",
      },
      description: {
        en: "<p>Effortlessly share patient cases and information electronically with e-Connect! This tool allows healthcare providers to easily consult peers across different clinics and streamline patient referrals between organizations. By using e-Connect, you will not only boost referral numbers and optimize your operations but also ensure smooth and uninterrupted patient journeys.</p>",
        se: "<p>Dela patientärenden och patientinformation med andra vårdgivare elektroniskt! e-Connect gör det möjligt för vårdgivare att konsultera personal på andra mottagningar samt att sömlöst remittera patienter mellan olika organisationer. Genom e-Connect kan ni inte bara öka antalet remisser och effektivisera era processer, utan även leverera en sömlös vårdupplevelse för era patienter.</p>",
      },
      imageOrVideo:
        "/uploads/Send_and_receive_referrals_through_the_Curoflow_telemedicine_software.webp",
      link: "var-plattform/konsultationer-och-remittering-mellan-vardgivare",
      btnText: { en: "Discover e-Connect", se: "Upptäck e-Connect" },
      edit: 6,
    },
    {
      tabname: { se: "Teknik & support", en: "Technology & support" },
      title: { se: "Teknik & support", en: "Technology & support" },
      description: {
        se: "<p>Curoflow plattform är helt utvecklad av oss och vi integrerar ständigt nya journalsystem och kommunikationsverktyg. Den digitala vårdplattformen är CE-märkt i enlighet med MDR och vi behandlar all data inom EU för strikt efterlevnad av GDPR. Vi hjälper alla kunder med onboarding och vårt supportteam är tillgängligt sju dagar i veckan om ni behöver hjälp.</p>",
        en: "<p>The Curoflow platform is entirely developed by us for complete control over our technology and service delivery. Our platform is CE-marked in accordance with the MDR, and we process all data within the EU to ensure strict compliance with the GDPR. We assist all customers with onboarding, and our support team is available seven days a week for any assistance you may require.</p>",
      },
      imageOrVideo:
        "/uploads/Curoflow_staff_help_healthcare_professional_with_telemedicine_functions.webp",
      link: "var-plattform/teknik-och-support-i-digitala-vardplattformen",
      btnText: {
        se: "Upptäck vår teknik & support",
        en: "Discover our technology & support",
      },
      edit: 7,
    },
  ],
  titleBelowFirstSlider: {
    en: "Let's have a lunch?",
    se: "Får vi bjuda dig på lunch?",
  },
  imageBelowFirstSlider:
    "/uploads/Curoflow_telemedicine_plaform_is_white_label_on_both_computer_and_mobile_app.jpg",
  titleAboveSecondSlider: {
    en: "Modern functions for digital healthcare",
    se: "Moderna funktioner för digifysisk vård",
  },
  descriptionAboveSecondSlider: {
    en: '<h6><span style="white-space-collapse: preserve;">Imagine a world where administrative tasks no longer consume all your precious time, and you can provide patient-centered care that exceeds expectations. With Curoflow, you can create a seamless flow in patient care, ensuring patient safety and data security. Streamline your processes, improve patient outcomes, and experience the power of Curoflow\'s revolutionary solutions that puts healthcare back in your hands. Join the revolution and discover the possibilities of the Curoflow telemedicine platform today.</span></h6>',
    se: "<h6>F&ouml;rest&auml;ll dig en v&auml;rld d&auml;r administrativa uppgifter inte l&auml;ngre tar upp all er v&auml;rdefulla tid och d&auml;r ni kan erbjuda en patientcentrerad v&aring;rd som &ouml;vertr&auml;ffar f&ouml;rv&auml;ntningarna. Med Curoflow kan ni skapa s&ouml;ml&ouml;sa v&aring;rdfl&ouml;den som garanterar s&aring;v&auml;l patient- som datas&auml;kerhet. Effektivisera era processer, f&ouml;rb&auml;ttra patientresultaten och upplev kraften i Curoflows revolutionerande l&ouml;sningar som m&ouml;jligg&ouml;r en effektivare och mer patientn&auml;ra v&aring;rd.&nbsp;</h6>",
  },
  descriptionBelowSecondSlider: {
    se: "<h6>Upplev kraften hos Curoflow! S&ouml;ml&ouml;s digifysisk v&aring;rd genom f&ouml;renklade arbetsfl&ouml;den, anpassningsbara l&ouml;sningar, f&ouml;rb&auml;ttrade patientupplevelser, en integrerad plattform och en fram&aring;triktad strategi</h6>",
    en: "<h6>Experience the power of Curoflow for seamless telemedicine, with simplified workflows, customizable solutions, enhanced patient experiences, an integrated platform, and a future-focused approach.</h6>",
  },
  imageBelowSeconSlider: "/uploads/imageClient.png",
  titleBelowSecondSlider: {
    en: "Why Curoflow telemedicine platform?",
    se: "Varför Curoflow digital vårdplattform?",
  },
  section4: {
    text1: {
      se: "<p>Hela plattformen, inklusive videotj&auml;nsten, behandlar all data inom Sverige f&ouml;r strikt efterlevnad av GDPR och PDL</p>",
      en: '<p><span style="white-space-collapse: preserve;">The whole platform, including the video-service, is developed and hosted within the EU for strict GDPR compliance</span></p>',
    },
    text2: {
      se: "<p>Curoflow &auml;r utvecklad av sjukv&aring;rdspersonal f&ouml;r att spara tid och ge smidigare patientfl&ouml;den</p>",
      en: '<p><span style="white-space-collapse: preserve;">Curoflow is developed by healthcare professionals to save time and make patient flows go smoother</span></p>',
    },
    text3: {
      se: "<p>Integration med journalsystem f&ouml;r online-bokningar och smidig export av journalanteckningar</p>",
      en: '<p><span style="white-space-collapse: preserve;">Integration with electronic health records for online bookings and simple export of journal notes</span></p>',
    },
    text4: {
      se: "<p>Monitorering av kroniska tillst&aring;nd, med v&auml;rdefull statistik, hj&auml;lper er att fatta datadrivna beslut</p>",
      en: '<p><span style="white-space-collapse: preserve;">State of the art chronic condition monitoring helping you make data-driven decisions</span></p>',
    },
    text5: {
      se: "<p>Design och arbetsfl&ouml;den kan skr&auml;ddarsys f&ouml;r att passa v&aring;rdgivare av alla typer och storlekar</p>",
      en: '<p><span style="white-space-collapse: preserve;">Design and workflows can be customized to suit healthcare providers of all types and sizes</span></p>',
    },
    text6: {
      se: "<p>Sj&auml;lvbetj&auml;ning f&ouml;r patienter, automatisering och dynamisk kommunikation g&ouml;r att ni kan hj&auml;lpa fler patienter</p>",
      en: '<p><span style="white-space-collapse: preserve;">Patient self-service, automation and dynamic communication enables you to help more patients</span></p>',
    },
    text7: {
      se: "<p>Era patienter kan enkelt följa och hantera sina vårdärenden genom er webb- eller appbaserade patientportal</p>",
      en: '<p><span style="white-space-collapse: preserve;">Your patients can easily follow and manage their care through your online patient portal</span></p>',
    },
    text8: {
      se: "<p>St&auml;rk varum&auml;rket och patientlojaliteten med djupg&aring;ende white label-funktionalitet</p>",
      en: '<p><span style="white-space-collapse: preserve;">Boost brand and patient loyalty with extensive white-label functionality. It&rsquo;s all about you!</span></p>',
    },
    image1: "/uploads/Secure_&_GDPR_compliant.svg",
    image2: "/uploads/Save_time_in_your_healthcare.svg",
    image3: "/uploads/Online_bookings_&_journal_notes.svg",
    image4: "/uploads/Monitoring_of_chronic_conditions.svg",
    image5: "/uploads/Design_&_customization.svg",
    image6: "/uploads/Help_more_patients.svg",
    image7: "/uploads/mobil_app_patient_portal.svg",
    image8: "/uploads/white_label_functions.svg",
  },
  section8: {
    title: {
      se: "<h1>Anpassad f&ouml;r just er v&aring;rdverksamhet</h1>",
      en: "<h1>Customized to fit your healthcare practice&nbsp;</h1>",
    },
    description: {
      se: "<h6>Curoflow plattform &auml;r anpassningsbar och skalbar f&ouml;r att m&ouml;ta behoven hos olika v&aring;rdverksamheter, fr&aring;n enskilda kliniker till hela kommuner och regioner. Upplev Curoflows flexibilitet och styrka n&auml;r plattformen smidigt utformas efter er unika v&aring;rdmilj&ouml; med optimerade arbetsfl&ouml;den och f&ouml;rb&auml;ttrad patientv&aring;rd.</h6>",
      en: "<h6>The Curoflow telemedicine software platform is designed to seamlessly adapt and scale to meet the needs of diverse healthcare practices, ranging from individual clinics to extensive municipal and regional networks. Experience the flexibility and power of Curoflow as it effortlessly caters to your unique healthcare setting, ensuring optimized workflows and enhanced patient care.&nbsp;</h6>",
    },
  },
  thirdSliderItems: [
    {
      imageOrVideo:
        "/uploads/Aleris_psychiatry_clinic_through_Curoflow_telemedicine_software.jpg",
      imageLogo: "/uploads/Aleris_use_Curoflow_telemedicine_software_for_digital_patient_meetings.png",
      description: {
        se: '<p>"Curoflow har gett oss ett smidigare patientflöde och gjort det möjligt att ha all digital patientinteraktion i ett och samma system. Genom Curoflows plattform kan patienterna enkelt skicka in sin egenremiss och när som helst få stöd av oss via chatt och videomöten. Det har också varit enkelt att anpassa frågeformulär och flöden. Vi är mycket glada över att både vår personal och våra patienter tycker att verktyget är praktiskt och enkelt."</p>\n<p>&nbsp;</p>\n<p>Lina Anderhell Öberg<br />Enhetschef, Aleris Digital Psykiatri</p>',
        en: '<p>"Curoflow has given us a smoother patient flow and made it possible to have all digital patient interaction in one system. Through Curoflow\'s platform, patients can easily submit their self-referral and get support from us at any time via chat and video meetings. It has also been easy to customize questionnaires and flows. We are delighted that both our staff and our patients find the tool practical and easy."</p>\n<p>&nbsp;</p>\n<p>Lina Anderhell Öberg<br />Unit Manager, Aleris Digital Psychiatry</p>',
      },
      edit: 0,
    },
    {
      imageOrVideo:
        "/uploads/Accesscare_using_Curoflow_telemedicine_platform_for_advanced_in-home_healthcare.jpg",
      imageLogo: "/uploads/AccessCare_use_Curoflow_telemedicine.png",
      description: {
        se: '<h6>"Curoflow är en trygghet för våra patienter då de enkelt kan kontakta oss när som helst. Vi bedriver avancerad sjukvård i hemmet och Curoflow ger oss ett mycket bra sätt att möta våra patienter även på distans. På det sättet ökar vi tillgängligheten samtidigt som vi även underlättar för vår personal i sitt dagliga arbete. Såväl formulärverktyg, gränssnitt, chatt och video har varit uppskattade funktioner som framtidssäkrar vår verksamhet och ser till att vi ligger i framkant."</h6>\n<p>&nbsp;</p>\n<p>Arja Autere<br />Verksamhetschef, ASiH AccessCare</p>',
        en: '<p>"Curoflow provides peace of mind for our patients as they can easily contact us at any time. We deliver advanced healthcare services to patients in their homes, and Curoflow offers us an excellent way to engage with our patients, even at a distance. This improves accessibility while also streamlining our staff\'s daily work. The interface with it&acute;s medical forms, chat, and video functionalities have been well-received features that future-proof our operations and ensure that we remain at the forefront."</p>\n<p>&nbsp;</p>\n<p>Arja Autere<br />Operations Manager, ASiH AccessCare</p>',
      },
      edit: 1,
    },
    // {
    //   imageOrVideo:
    //     "/uploads/Migränhjälpen_telemedicine_mobile_app_by_Curoflow_software_provider.jpg",
    //   imageLogo: "/uploads/Migränhjälpen_use_Curoflow_telemedicine.png",
    //   description: {
    //     en: '<h3>"Curoflow has a well-functioning and clear-cut platform. They are also very responsive and quick to make improvements in the platform. As a customer, you feel that they are really listening to you."</h3>\n<p>&nbsp;</p>\n<p>Mattias Bodin<br />CEO, Migr&auml;nhj&auml;lpen</p>',
    //     se: '<h3>"Curoflow har en bra och tydlig plattform. De &auml;r ocks&aring; v&auml;ldigt lyh&ouml;rda och snabba med att komma med f&ouml;rb&auml;ttringar i plattformen, s&aring; som kund k&auml;nner man att de verkligen lyssnar p&aring; en."</h3>\n<p>Mattias Bodin</p>\n<p>VD, Migr&auml;nhj&auml;lpen</p>',
    //   },
    //   edit: 2,
    // },
    {
      imageOrVideo:
        "/uploads/Gynhälsan_IVF_clinic_using_Curoflow_telemedicine_software_for_patient_communication.jpg",
      imageLogo: "/uploads/Gynhälsan_Curoflow_customer.png",
      description: {
        se: '<h3>"Curoflows plattform ger oss ett fantastiskt smidigt och enkelt sätt att kommunicera med våra patienter."</h3>\n<p>Marianne Holmgren Windh</p>\n<p>Gynekolog, Gynh&auml;lsan IVF</p>',
        en: "<h3>“The Curoflow telemedicine platform gives us an amazingly flexible and easy way to communicate with our patients.”</h3>\n<p>&nbsp;</p>\n<p>Marianne Holmgren Windh<br />Gynecologist, Gynh&auml;lsan IVF</p>",
      },
      edit: 3,
    },
    {
      imageOrVideo:
        "/uploads/Ekenhälsan_patient_portal_through_Curoflow_telemedicine_software.jpg",
      edit: 4,
      imageLogo: "/uploads/Ekenhälsan_use_Curoflow_telemedicine_software.png",
      description: {
        se: '<p class="MsoNormal">&ldquo;Curoflow har minskat administrationen och v&aring;ra telefonk&ouml;er vilket &auml;r ett stort lyft f&ouml;r b&aring;de v&aring;r personal och v&aring;ra patienter! Genom mobilappen kan patienterna chatta med oss, f&aring; provsvar, f&ouml;rnya recept m.m och v&aring;r personal hanterar inkommande &auml;renden n&auml;r det passar dem. Curoflow &auml;r white label p&aring; riktigt och smidig att anpassa vad g&auml;ller b&aring;de funktion och utseende. Patienternas funktioner i appen har vi format efter v&aring;ra fl&ouml;den och hela gr&auml;nssnittet &auml;r designat utefter hur vi vill se ut. Det k&auml;nns &auml;ven bra att Curoflow p&aring; riktigt lever upp till GDPR och inte anv&auml;nder sig av utomeuropeiska molntj&auml;nster som riskerar v&aring;ra patienters integritet."</p>\n<p>&nbsp;</p>\n<p>Johan Meisel</p>\n<p>IT-ansvarig, Ekenh&auml;lsan</p>',
        en: "<p>\"Curoflow has reduced administration and our telephone queues, which is a great improvement for both our staff and our patients! Through the mobile app, patients can chat with us, get test results, renew prescriptions etc. and our staff handle incoming cases when it suits them. Curoflow is truly white label and easy to customize in terms of both function and appearance. We have customized the patients' functions in the app according to our flows and the entire interface is designed to reflect our brand identity. It's also good to know that Curoflow truly lives up to GDPR and does not use overseas cloud services that risk our patients' privacy.\"</p>\n<p>&nbsp;</p>\n<p>Johan Meisel</p>\n<p>IT Manager, Ekenh&auml;lsan</p>",
      },
    },
    {
      imageOrVideo:
        "/uploads/gävle_läkarmottagning_uses_curoflow_telemedicine_platform.webp",
      edit: 4,
      imageLogo: "/uploads/Gävle_läkarmottagning_uses_curoflow_telemedicine_platform.webp",
      description: {
        se: '<p class="MsoNormal">\"Curoflow har förbättrat vårt patientflöde och gör det möjligt för våra patienter att boka sin tid när som helst på dygnet. Med Curoflows plattform kan patienter enkelt se vilka tjänster och tider som erbjuds, de kan hantera sina bokningar och få påminnelser om sina besök. Vi och våra patienter är mycket nöjda över detta då det ökar både tillgängligheten och effektiviteten i vår verksamhet. Det har också minskat den administrativa belastningen på vår personal, vilket gör att vi kan fokusera mer på att ge kvalitativ vård.\"</p>\n<p>&nbsp;</p>\n<p>Henrik Wrethling</p>\n<p>Verksamhetschef och läkare i Allmänmedicin, Gävle Läkarmottagning</p>',
        en: "<p>\"Curoflow has improved our patient flow, allowing patients to book their appointments at any time of the day or night. Using Curoflow's platform, patients can effortlessly browse available services and appointment slots, manage their bookings, and receive timely reminders for their visits. Our staff and patients are very pleased with this, as it enhances both the accessibility and efficiency of our operations. It has also reduced the administrative burden, enabling us to focus more on delivering high-quality care.\"</p>\n<p>&nbsp;</p>\n<p>Henrik Wrethling</p>\n<p>Head of Operations and General Practitioner, Gävle Läkarmottagning</p>",
      },
    },
  ],
  lastSliderITems: [
    {
      tabname: { se: "Primärvård", en: "Primary care" },
      title: { se: "Primärvårdsmottagning", en: "Primary care clinic" },
      description: {
        se: "<p>&Ouml;ka bekv&auml;mligheten och spara tid f&ouml;r era patienter genom att l&aring;ta dem n&aring; er v&aring;rdmottagning online. Via er digitala portal kan patienterna f&aring; information, s&ouml;ka v&aring;rd, triageras, redog&ouml;ra f&ouml;r sina besv&auml;r och boka tider. Det frig&ouml;r tid f&ouml;r er och till patienter som beh&ouml;ver bes&ouml;ka er fysiskt. Curoflow erbjuder en m&auml;ngd funktioner och integrationer f&ouml;r att underl&auml;tta er och era patienters vardag! &nbsp;</p>",
        en: "<p>Enhance convenience and save time by allowing your patients to access your primary care clinic online. Through your digital portal, patients can obtain information, seek medical treatment, and self-book appointments, thus freeing up time for those requiring in-person visits. Asynchronous chat proves invaluable in primary care, and Curoflow additionally offers customizable collections of quick phrases for efficient communication.</p>",
      },
      imageOrVideo:
        "/uploads/Nurses_exporting_anamnesis_to_medical_records_in_Curoflow_patient_portal.jpg",
      link: "vi-hjalper/digital-vardplattform-for-primarvard",
      btnText: {
        se: "Upptäck Curoflow för primärvården",
        en: "Discover Curoflow for primary care",
      },
      edit: 0,
    },
    {
      tabname: { en: "Specialty care", se: "Specialistvård" },
      title: { en: "Specialty care", se: "Specialistvård" },
      description: {
        en: "<p>Curoflow is a white-label solution that can be customized for each clinic's specialty, unique flows, and needs. You control which functions patients can access in the portal, and the dynamic forms for capturing status and medical history are tailored to your practice. With calendars and patient journals for monitoring and support in longer treatments, Curoflow is ideal for patients with chronic conditions.</p>",
        se: "<p>Curoflow &auml;r white label och anpassas efter varje mottagnings specialitet, unika fl&ouml;den och behov. Ni styr vilka funktioner som patienterna f&aring;r tillg&aring;ng till i den digitala portalen. De dynamiska formul&auml;ren f&ouml;r anamnesupptagning skr&auml;ddarsys f&ouml;r just er mottagning. Med kalendrar och patientdagb&ouml;cker f&ouml;r uppf&ouml;ljning och st&ouml;d vid l&auml;ngre behandlingar &auml;r Curoflow perfekt f&ouml;r patienter med kroniska tillst&aring;nd.</p>",
      },
      imageOrVideo:
        "/uploads/Healthcare_personnel_at_obgyn_clinic_working_in_Curoflow_telemedicine_platform.jpg",
      link: "vi-hjalper/digital-vardplattform-for-specialistvard",
      btnText: {
        en: "Discover Curoflow for specialty care",
        se: "Upptäck Curoflow för specialistvård",
      },
      edit: 1,
    },
    {
      tabname: { se: "Fertilitetsvård", en: "Fertility care" },
      title: { se: "Fertilitetsvård", en: "Fertility care" },
      description: {
        se: "<p> Curoflow underlättar kommunikationen under fertilitetsutredningar och behandlingar. Väntetider förkortas och antalet uteblivna besök minskar. Plattformens dagbok är mycket uppskattad för planering, uppföljning och monitorering av fertilitetsbehandlingar. För IVF-patienter med en mängd aktiviteter att hålla reda på är Curoflow ett pålitligt stöd.</p>",
        en: "<p>The Curoflow telemedicine platform helps facilitate communication throughout fertility investigations and treatments. It reduces wait times and lowers the risk of missed appointments, enhancing the likelihood of a successful outcome. The Curoflow calendar and patient journal are highly valued for planning and monitoring fertility treatments. With numerous tasks to manage, IVF patients rely on Curoflow as a dependable source of support.</p>",
      },
      imageOrVideo:
        "/uploads/Video_meeting_with_an_IVF_fertility_clinic_through_Curoflow_telemedicine_software.jpg",
      link: "vi-hjalper/digital-vardplattform-for-fertilitetsvard",
      btnText: {
        se: "Upptäck Curoflow för fertilitetsvård",
        en: "Discover Curoflow for fertility care",
      },
      edit: 2,
    },
    {
      tabname: { se: "Företagshälsovård", en: "Corporate healthcare" },
      title: { se: "Företagshälsovård", en: "Corporate healthcare" },
      description: {
        se: "<p>Erbjud tillg&auml;nglig och flexibel f&ouml;retagsh&auml;lsov&aring;rd med Curoflow! Plattformen ger er en direkt digital kanal till era klienter med chatt, videom&ouml;ten, schemal&auml;ggning av m&ouml;ten och mycket mer. Genom Curoflow kan ni enkelt distribuera instruktionsvideor och bjuda in anst&auml;llda till fysiska tester och h&auml;lsounders&ouml;kningar. Curoflow reducerar &auml;ven restiden och g&ouml;r det m&ouml;jligt f&ouml;r anst&auml;llda att kommunicera hemifr&aring;n n&auml;r de &auml;r sjukskrivna.</p>",
        en: "<p>Offer accessible and flexible corporate healthcare with Curoflow! The platform gives you a direct digital channel to your clients for chat, video meetings, scheduling of appointments and much more. Through Curoflow you can easily distribute instructional videos and invite employees for physical tests and health screenings. Additionally, Curoflow reduces travel time and allows employees to communicate from home while on sick leave.</p>",
      },
      imageOrVideo:
        "/uploads/Corporate_healthcare_through_Curoflow_telemedicine_software.jpg",
      link: "vi-hjalper/digital-vardplattform-for-foretagshalsovard",
      btnText: {
        se: "Upptäck Curoflow för företagshälsovård",
        en: "Discover Curoflow for corporate healthcare",
      },
      edit: 3,
    },
    {
      tabname: { se: "Elevhälsa", en: "Student care services" },
      title: { se: "Elevhälsa", en: "Student care services" },
      description: {
        se: "<p>M&ouml;t eleven p&aring; elevens villkor. Genom app eller webbgr&auml;nssnitt m&ouml;jligg&ouml;r Curoflow flexibel kommunikation och &ouml;kar tillg&auml;ngligheten till elevh&auml;lsan f&ouml;r b&aring;de elever och deras v&aring;rdnadshavare. Dagboksfunktionen m&ouml;jligg&ouml;r h&auml;lsomonitorering av elever och ger strukturerad data f&ouml;r statistik och resursplanering. Anv&auml;nd Curoflow digital v&aring;rdplattform f&ouml;r att dela p&aring; personalresurser och f&ouml;rb&auml;ttra elevernas tillg&aring;ng till god v&aring;rd!</p>",

        en: "<p>Digital access and flexible communication through Curoflow enhance access to student healthcare services for students and their guardians. The personal journal and health tracker enable monitoring of students' well-being, providing structured data for statistics and resource planning. Utilize the Curoflow telemedicine software to share staff resources and enhance students' access to specialized care!</p>",
      },
      imageOrVideo:
        "/uploads/Appointment_booking_at_the_student_health_centre_through_Curoflow_telemedicine_app.jpg",
      link: "vi-hjalper/digital-vardplattform-for-elevhalsa",
      btnText: {
        se: "Upptäck Curoflow för elevhälsa",
        en: "Discover Curoflow for student care",
      },
      edit: 4,
    },
    {
      tabname: { se: "Hemsjukvård", en: "Home healthcare" },
      title: { se: "Hemsjukvård", en: "Home healthcare" },
      description: {
        se: "<p>Patienter som f&aring;r sjukv&aring;rd i hemmet kan genom Curoflow enkelt h&aring;lla kontakten med er via sin mobiltelefon. F&ouml;rutom s&auml;ker chatt och videosamtal d&auml;r ni p&aring; ett s&auml;kert s&auml;tt kan bjuda in externa personer, m&ouml;jligg&ouml;r &auml;ven plattformen monitorering av patienterna p&aring; distans. Denna v&auml;rdefulla information underl&auml;ttar f&ouml;rberedelserna inf&ouml;r n&auml;sta fysiska bes&ouml;k och minimerar on&ouml;diga resor.</p>",
        en: "<p>With Curoflow, patients receiving home care can conveniently stay connected with you via their smartphone. In addition to secure chat and video calls, where you can securely invite external people, you can also monitor patients remotely. This valuable information enables you to prepare for physical visits by bringing the necessary assistance and medication, minimizing unnecessary trips.</p>",
      },
      imageOrVideo:
        "/uploads/Home_health_care_patient_using_Curoflow_telemedicine_platform.jpg",
      link: "vi-hjalper/digital-vardplattform-for-hemsjukvard",
      btnText: {
        se: "Upptäck Curoflow för hemsjukvård",
        en: "Discover Curoflow for home healthcare",
      },
      edit: 5,
    },
    {
      tabname: { se: "Omsorg", en: "Community care" },
      title: { se: "Omsorg", en: "Community care" },
      description: {
        se: "<p>Curoflow effektiviserar kommunikation och &auml;rendehantering, oavsett om det g&auml;ller socialtj&auml;nst, barnomsorg, hemtj&auml;nst eller s&auml;rskilt boende. Digitala ing&aring;ngar f&ouml;rb&auml;ttrar tillg&auml;ngligheten och med GDPR-s&auml;ker chatt/video f&ouml;renklas dialogen b&aring;de interprofessionellt och med era klienter. I Curoflow plattform har ni m&ouml;jlighet att designa b&aring;de utseende och funktionalitet f&ouml;r varje enhet i er organisation.</p>",
        en: "<p>Curoflow streamlines communication and case management, regardless of whether it is for social services, childcare, home care or nursing homes. Digital entry points enhance accessibility and GDPR-secure chat/video facilitates both interprofessional and client interactions. With Curoflow you have the ability to design both appearance and functionality of your digital interface, for each unit in your organization.</p>",
      },
      imageOrVideo:
        "/uploads/Community_care_video_meeting_through_Curoflow_telemedicine_app.jpg",
      link: "vi-hjalper/digital-vardplattform-for-kommunal-omsorg",
      btnText: {
        se: "Upptäck Curoflow för omsorg",
        en: "Discover Curoflow for community care",
      },
      edit: 6,
    },
    {
      tabname: { se: "Region", en: "Healthcare organization" },
      title: { se: "Region", en: "Large healthcare organization" },
      description: {
        se: "<p>Curoflow l&aring;ter er anv&auml;nda en enhetlig digital kommunikationsplattform p&aring; alla enheter i er region. Ni best&auml;mmer sj&auml;lva hur ni vill strukturera avdelningar, kliniker, appar och webbgr&auml;nssnitt f&ouml;r era patienters digitala ing&aring;ngar. Eftersom Curoflow erbjuds som white label, kan patientportalerna utformas efter regionens olika profiler, och varje enhet kan sj&auml;lva best&auml;mma hur funktionerna ska skr&auml;ddarsys f&ouml;r just deras verksamhet.</p>",
        en: "<p>With Curoflow, you can deploy a unified communication platform across all units in your diverse organization. You decide how you want to structure the departments, clinics, apps and web interfaces for your patients' digital access. Since Curoflow is a white-label product, the patient portals are designed according to the organization's different profiles, and each healthcare provider decides how to tailor the features to their specific business.</p>",
      },
      imageOrVideo:
        "/uploads/Doctor_at_hospital_in_video_meeting_through_Curoflow_telemedicine_platform.jpg",
      link: "vi-hjalper/digital-vardplattform-for-regioner",
      btnText: {
        se: "Upptäck Curoflow för er region",
        en: "Discover Curoflow for healthcare organizations",
      },
      edit: 7,
    },
    {
      tabname: { se: "Kommun", en: "Municipality" },
      title: { se: "Kommun", en: "Municipality" },
      description: {
        se: "<p>F&ouml;rb&auml;ttra tillg&auml;ngligheten och kommunikationen i din kommun! Curoflow &auml;r utformat f&ouml;r att st&ouml;dja medborgar- och patienthantering inom ett brett spektrum av tj&auml;nster. Det m&ouml;jligg&ouml;r att ni kan implementera en enhetlig digital kommunikationsplattform hos alla enheter i er kommun. Genom s&auml;kra inlogg och databehandling i Sverige m&ouml;jligg&ouml;r v&aring;r videotj&auml;nst s&auml;kra flerpartssamtal som strikt f&ouml;ljer GDPR.</p>",
        en: "<p>Improve access and communication in your municipality! Curoflow is designed to support resident and patient management across a wide range of services. It enbles you to deploy a consistent digital communication platform across all units in your municipality. With protected logins and data processing exclusively in Sweden, Curoflow's video service enables secure multi-party calls in strict compliance to the GDPR.</p>",
      },
      imageOrVideo: {
        en: "/uploads/Telemedicine_software_for_municipalities.webp",
        se: "/uploads/Digital_vårdplattform_för_kommuner.webp"
      },
      link: "vi-hjalper/digital-kommunikationsplattform-for-kommuner",
      btnText: {
        se: "Upptäck Curoflow för er kommun",
        en: "Discover Curoflow for municipalities",
      },
      edit: 8,
    },
  ],
  section7: {
    title: {
      se: "<h1>V&aring;ra kunder ber&auml;ttar</h1>",
      en: "<h1>Voices of success&nbsp;</h1>",
    },
    description: {
      se: "<h6>H&ouml;r v&aring;ra kunder ber&auml;tta om hur Curoflow har lyft deras verksamheter. Uppt&auml;ck hur v&aring;r digitala v&aring;rdplattform har transformerat deras v&aring;rdmottagningar, f&ouml;rb&auml;ttrat patientv&aring;rden och &ouml;kat effektiviteten. G&ouml;r som v&aring;ra kunder och uppt&auml;ck potentialen i digifysisk v&aring;rd genom Curoflow.</h6>",
      en: "<h6>Hear from our customers: real stories of success with the Curoflow telemedicine software platform. Discover how Curoflow has transformed their practices, improved patient care, and enhanced operational efficiency. Join them on their journey and unlock the potential of digital healthcare.</h6>",
    },
  },
  section6: {
    description1: {
      se: "<p>Sparad tid p&aring; administrativa uppgifter</p>",
      en: "<p>Time saved on administrative tasks&nbsp;</p>",
    },
    description2: {
      se: "<p>Fler patient&auml;renden per v&aring;rdpersonal</p>",
      en: "<p>More patient cases per healthcare professional&nbsp;</p>",
    },
    description3: { se: "<p>ROI</p>", en: "<p>ROI&nbsp;</p>" },
    title: {
      se: "<h1>Effektivisera er v&aring;rd genom Curoflow</h1>",
      en: "<h1>The impact of Curoflow in your care&nbsp;</h1>",
    },
    subtitle: {
      se: "<h6>Curoflow v&aring;rdplattform optimerar patienthanteringen och f&ouml;rb&auml;ttrar resultaten i er verksamhet</h6>",
      en: "<h6>Reap the business benefits enabled by the Curoflow telemedicine software platform</h6>",
    },
  },
  section5: {
    title: {
      se: "<h1>Flyt in i framtiden:</h1>\n<h1>Er digitala plattform f&ouml;r digifysisk v&aring;rd</h1>",
      en: "<h1>Flow into the future:</h1>\n<h1>Your digital healthcare platform</h1>",
    },
    subtitle: {
      se: "<h6>Skapa framtidens sjukvård med Curoflow: Starta er egen digifysiska vårdmottagning och hantera era patienter online. Vi kan digital vård, teknik och datasäkerhet vilket ger er friheten att fokusera på er verksamhet och era patienter. Låt oss tillsammans utforma en patientcentrerad vård för er verksamhet genom förbättrad tillgänglighet, smidiga möten, användarvänliga gränssnitt och automatiserade processer. Upptäck möjligheterna med Curoflow vårdplattform.</h6>",
      en: "<h6>Unleash the future of healthcare with Curoflow: build your digital care center and connect with patients online. We embrace digital health, technology, and data security, empowering you to focus on your practice and patients. Together, let's redefine patient-centric care through enhanced accessibility, streamlined encounters, intuitive interfaces, and automated processes. Discover the possibilities of the Curoflow platform.</h6>",
    },
    pdfswe: {
      fileName: "Curoflow-Produktblad-2024.pdf",
      file: "/uploads/Curoflow-Produktblad-2024.pdf",
    },
    titleAndSubtitle: {
      se: "<h3>Frig&ouml;r tid och f&ouml;rb&auml;ttra arbetsmilj&ouml;n f&ouml;r er personal</h3>\n<p>Stärk er vårdpersonal i deras dagliga arbete! Självbetjäning för patienter, asynkron digital kommunikation, och automatiserade processer frigör mycket tid till att ge en god vård. Förkortade väntetider och färre personer i era lokaler minskar även stressen för er vårdpersonal. Omfamna distansarbete, optimera personalresurser och utöka er räckvidd så ni kan hjälpa patienter från större geografiska områden. Upptäck vad Curoflow kan göra för er verksamhet!</p>",
      en: "<h3>Free up time and improve the work environment for your staff</h3>\n<p>Unleash the power of Curoflow: empower healthcare providers with self-service patient management, asynchronous digital communication, and automated processes, saving precious time for quality care. Shortened wait times and reduced crowding relieve stress on healthcare staff. Embrace remote work, optimize staff resources, and expand your reach to serve patients from a larger geographic area. Experience the Curoflow telemedicine platform and revolutionize your practice today!</p>",
    },
    image1:
      "/uploads/Nurse_working_with_patient_triage_in_Curoflow_telemedicine_software_platform.jpg",
    titleAndSubtitle1: {
      se: "<h3>G&ouml;r v&aring;rden l&auml;ttare och mer tillg&auml;nglig f&ouml;r era patienter</h3>\n<p>Gör vården smidigare för era patienter genom att låta dem interagera med er mottagning när och var som helst, utan att behöva vänta i telefonsamtal eller väntrum. Med Curoflow kan patienterna enkelt skapa, följa och hantera sina vårdärenden digitalt. Plattformen möjliggör privata vårdmöten under hektiska vardagar och att patienter snabbt kan få hjälp med sina besvär. Eftersom fysisk kapacitet frigörs av det digitala kommer era patienter även uppleva kortare ledtider för mer avancerade bedömningar och behandlingar.</p>",
      en: "<h3>Make healthcare faster and more accessible for your patients</h3>\n<p>Empower your patients by allowing them to interact with your healthcare clinic anytime and anywhere, without having to wait on the phone or in a waiting room. With Curoflow, patients can easily create, track, and manage their healthcare matters digitally. The telemedicine platform enables private healthcare appointments during busy weekdays and allows patients to quickly receive assistance for minor issues. As physical capacity is freed up by the digital system, your patients will also experience shorter wait times for more advanced treatments. Provide your patients with the convenience they deserve!</p>",
    },
    image2: {
      en: "/uploads/Digital_healthcare_with_app_through_Curoflow_telemedicine_platform.webp",
      se: "/uploads/Erbjud_digital_vård_genom_din_app_med_Curoflow_digital_vårdplattform.webp"
    },
    pdfen: {
      fileName:
        "Curoflow-Product-Sheet-2024.pdf",
      file: "/uploads/Curoflow-Product-Sheet-2024.pdf",
    },
  },
  section9: {
    title: {
      se: "<h2>&Auml;r du nyfiken p&aring; Curoflow v&aring;rdplattform?</h2>",
      en: "<h2>Would you like a demo of our telemedicine platform?</h2>",
    },
    description: {
      se: "<p>Undrar du hur den digitala v&aring;rdplattformen ser ut och hur den hade fungerat i ert dagliga arbete? Vill du prata med en leverant&ouml;r som kommer fr&aring;n v&aring;rden och f&ouml;rst&aring;r era behov? Vi diskuterar g&auml;rna er verksamhet och hur Curoflows olika funktioner skulle kunna effektivisera den. Skicka ett meddelande till oss s&aring; bokar vi in en demo av plattformen och ber&auml;ttar mer!&nbsp;</p>",
      en: "<p>Are you curious what the Curoflow telemedicine platform looks like and how it would work in your daily operations? Are you ready to switch to a software provider with a background in healthcare and who understands your needs? We are happy to discuss your business and how Curoflow’s different functions could transform it. Send us a message and we'll schedule a demo tailored to your practice!</p>",
    },
  },
};
