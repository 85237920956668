import React, { Component } from 'react';
import Link from "../components/link";
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import 'react-circular-progressbar/dist/styles.css';
import { API_ENDPOINT } from "../constants";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import ArrowForward from '../assets/images/arrow_forward.png'
import ArrowForward1 from '../assets/images/arrow_forward1.png'
import SupportForm from '../components/forms/supportForm';
import Img from '../components/Img';
import ContactForm from '../components/forms/contactForm';
import CuroflowIcon from "../assets/images/Curoflow_Digital_Telemedicine_Platform_Icon.png"
import AOS from 'aos';
import 'aos/dist/aos.css'
import ContactFormm from '../components/forms/contactForm27';
import Slider1Mob from '../assets/images/Curoflow_telemedicine_software_for_doctors.webp'
import VideoPlayer from '../components/VideoPlayer';
import mobImg from "../assets/images/Curoflow_develop_telemedicine_software_to_help_healthcare_workers.jpg"

var striptags = require('striptags');

function parseJSON(response) {
    return response.json().then((json) => {
        return {
            result: json,
            status: response.status,
        };
    });
}

function generateUniqueId() {
    return 'id-' + Math.random().toString(36).substr(2, 16);
}

const getUserId = () => {
    let id;
    if (typeof window !== 'undefined') {
        const savedId = localStorage?.getItem?.('user_id');
        if (savedId) id = savedId;
    };

    if (!id) id = generateUniqueId();

    if (typeof window !== 'undefined') {
        localStorage?.setItem?.('user_id', id)
    }

    return id;
}

class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.player = React.createRef();

        this.state = {
            playVideo: false,
            promotedProjects: [],
            poolActive: false,
            chatForm: false,
            yScroll2: 0,
            ourSolutions: [],
            solutionsStatus: false,
            ...props.initialData
        };
    }

    listenToScroll = () => {
        const yScroll2 =
            document.body.scrollTop || document.documentElement.scrollTop


        this.setState({
            yScroll2: yScroll2,
        })
    }

    sendMessage1 = (data) => {
        if (data.checked) {
            data.lang = this.props.lang
            this.setState({
                loading1: true
            }, () => {

                fetch(API_ENDPOINT + '/data/contact/new', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ data })
                }).then(parseJSON).then(({ result, status }) => {
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                    }
                })
            })
        }
    }

    sendMessage27 = (data) => {



        this.props.setRecaptchaCallback(() => {


            if (data && data.checked && this.props.allowSubmit) {
                if (data.email && (data.email.toLowerCase().indexOf('@gmail.com') !== -1 || data.email.toLowerCase().indexOf('@live.com') !== -1 || data.email.toLowerCase().indexOf('@mailinator.com') !== -1 || data.email.toLowerCase().indexOf('@jollyfree.com') !== -1 || data.email.toLowerCase().indexOf('@gotgel.org') !== -1 || data.email.toLowerCase().indexOf('@yahoo.com') !== -1 || data.email.toLowerCase().indexOf('@hotmail.com') !== -1 || data.email.toLowerCase().indexOf('@admin.com') !== -1 || data.email.toLowerCase().indexOf('@info.com') !== -1)) {
                    return this.setState({ contactFormError27: 'Please fill in your work email' })
                } else this.setState({ contactFormError27: null })

                // if (data.message?.length < 25) return this.setState({ contactFormMessageError27: 'Please use at least 25 letters' });
                // else this.setState({ contactFormMessageError27: null })

                data.lang = this.props.lang
                data.url = typeof window !== 'undefined' ? window.location?.pathname : 'No Data';
                data.bookADemo = false
                data.page = this.props.lang === "en" ? "home page" : "startsida"
                data.token = this.props.recaptchaToken
                data.isMobile = this.props.isMobile
                data.osMobileNameUA = this.props.osMobileNameUA
                data.browserName = this.props.browserName,
                    data.browserNameUA = this.props.browserNameUA
                this.setState({
                    loading2: true
                }, () => {

                    fetch(API_ENDPOINT + '/data/contact/new', {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify({ data })
                    }).then(parseJSON).then(({ result, status }) => {
                        if (result.error) {
                            this.setState({ error: result.error })
                        } else {
                            this.setState({
                                supportForm: false
                            }, () => {
                                const path = 'thank-you';
                                this.props[0].history.push(`/${path}`);
                            })

                        }
                    })

                })
            }
        })

        this.props.updateToken();


    }

    sendMessage2 = (data) => {
        this.props.setRecaptchaCallback(() => {

            if (data && data.checked && this.props.allowSubmit) {
                if (data.email && (data.email.toLowerCase().indexOf('@gmail.com') !== -1 || data.email.toLowerCase().indexOf('@live.com') !== -1 || data.email.toLowerCase().indexOf('@mailinator.com') !== -1 || data.email.toLowerCase().indexOf('@jollyfree.com') !== -1 || data.email.toLowerCase().indexOf('@gotgel.org') !== -1 || data.email.toLowerCase().indexOf('@yahoo.com') !== -1 || data.email.toLowerCase().indexOf('@hotmail.com') !== -1 || data.email.toLowerCase().indexOf('@business.com') !== -1 || data.email.toLowerCase().indexOf('@admin.com') !== -1 || data.email.toLowerCase().indexOf('@info.com') !== -1)) {
                    return this.setState({ contactFormError: 'Please fill in your work email' })
                } else this.setState({ contactFormError: null })

                // if (data.message?.length < 25) return this.setState({ contactFormMessageError: 'Please use at least 25 letters' });
                // else this.setState({ contactFormMessageError: null })

                data.lang = this.props.lang;
                data.url = typeof window !== 'undefined' ? window.location?.pathname : 'No Data';
                data.bookADemo = false
                data.page = this.props?.[0]?.match?.params?.alias?.replace(/-/g, ' ') || 'No Data';
                data.token = this.props.recaptchaToken
                data.isMobile = this.props.isMobile
                data.osMobileNameUA = this.props.osMobileNameUA
                data.browserName = this.props.browserName,
                    data.browserNameUA = this.props.browserNameUA
                this.setState({
                    loading2: true
                }, () => {

                    setTimeout(() => {
                        this.player?.current?.pause()
                    }, 1120)

                    fetch(API_ENDPOINT + '/data/contact/new', {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify({ data })
                    }).then(parseJSON).then(({ result, status }) => {
                        if (status === 429) {
                            this.setState({
                                solutionsStatus: true,
                                loading2: false
                            })
                        }
                        if (result.error) {
                            this.setState({ error: result.error })
                        } else {
                            this.props.bookDemo(false)
                            const path = 'thank-you';
                            this.props[0].history.push(`/${path}`);
                        }
                    })
                })
            }
        })
        this.props.updateToken();

    }

    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }


    }

    componentDidMount() {
        this.get();

        if (this.props[0].location && !this.props[0].location.hash) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
        } else if (this.props[0].location && this.props[0].location.hash) {
            document.getElementById(this.props[0].location.hash.replace('#', '')).scrollIntoView({ behavior: 'smooth', block: 'start' });
        }

        this.updateWindowDimensions();
        window.addEventListener('scroll', this.listenToScroll)

        AOS.init({
            duration: 1000
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            this.get()
        }
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    render() {

        return (
            <>
                <div>



                    <div className={this.props.openModalBookDemo ? 'contact-popup open' : 'contact-popup'}>
                        <span className="close-icon" onClick={() => {
                            this.props.bookDemo(false)
                        }}>x</span>
                        {
                            !this.state.loading3 && !this.state.supportStatus ?
                                <SupportForm onSubmit={this.sendMessage2} lang={this.props.lang} />
                                : null
                        }
                        {
                            this.state.loading3 ?
                                <Player
                                    ref={this.player}
                                    autoplay={true}
                                    onEvent={(event) => {
                                        if (event == 'complete') {
                                            this.setState({
                                                supportForm: false,
                                                loading3: false
                                            })
                                        }
                                    }}
                                    //keepLastFrame={true}
                                    //loop={false}
                                    controls={true}
                                    src="https://assets4.lottiefiles.com/packages/lf20_oaw8d1yt.json"
                                    style={{ height: '300px', width: '300px' }}
                                ></Player>
                                :
                                null
                        }
                        {this.state.supportStatus && <h2 className='status-popup support-status'>{'Please, try again later!'.translate(this.props.lang)}</h2>}
                    </div>
                    {
                        this.state.pageData ?
                            <div>
                                {
                                    this.state.pageData.section1 ?
                                        <section className='section-curoflow section-curoflow-img new-section-about'>
                                            <div>
                                                <div className='section-curoflow-content'>

                                                    <h1 className='go-flow-head'>{this.state.pageData && this.state.pageData.section[0]?.title[this.props.lang]}</h1>
                                                    <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[0], 'shortDescription', this.props.lang) }}></div>

                                                    <button className='home-btn-h1 video-btn' onClick={() => {
                                                        this.setState({
                                                            playVideo: true,
                                                            playVideoClicks: (this.state.playVideoClicks || 0) + 1
                                                        }, () => {
                                                            if (typeof window !== 'undefined') {
                                                                window.dataLayer = window.dataLayer || [];
                                                                function gtag() { dataLayer.push(arguments); }
                                                                // console.log({ user_id: getUserId() }, 'novi user id');
                                                                // console.log(getUserId(), 'user id event funckija koju proledjujem kao user id');
                                                                gtag('event', 'play_video_button_click_about_page', {
                                                                    value: this.state.playVideoClicks,
                                                                    userId: getUserId(),
                                                                    // event_label: "djolo"

                                                                })
                                                            }
                                                        })
                                                    }}>{'Play video'.translate(this.props.lang)}
                                                    </button>
                                                    <Link lang={this.props.lang} to="/kontakta-oss" className='home-btn-h1'> {"Contact us".translate(this.props.lang)}</Link>
                                                </div>
                                            </div>

                                            <div className='new-img'>
                                                <Img className="desktop-img" src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[0] && this.state.pageData.section[0].bigImage)} alt={(this.state.pageData && this.state.pageData.section[0] && this.state.pageData.section[0].bigImage?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                                <img src={Slider1Mob} className='mob-img' alt='Curoflow telemedicine software for doctors' />

                                            </div>

                                        </section>
                                        :
                                        null
                                }
                                {
                                    this.state.pageData.section2 ?
                                        <section className='curoflow-section curoflow-paddiing curoflow-bg-grey go-flow-section2 new-section2'>
                                            <div className="title">
                                                <h1>{this.state.pageData && this.state.pageData.section[1] && this.state.pageData.section[1].item[0]?.title[this.props.lang]}</h1>
                                                <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[1] && this.state.pageData.section[1].item[0], 'shortDescription1', this.props.lang) }}></div>
                                            </div>
                                            <img src={CuroflowIcon} className="rightSideGoFlow" alt="Curoflow Digital Telemedicine Platform Icon" />
                                        </section>
                                        :
                                        null
                                }
                                {
                                    this.state.pageData.section3 ?
                                        <section className='curoflow-section color-black'>
                                            <div className="container-curoflow column-reverse">
                                                <div className="col-curoflow-lg-6">
                                                    <h2>{this.state.pageData && this.state.pageData.section[2] && this.state.pageData.section[2].item[0]?.title[this.props.lang]}</h2>
                                                    <div className="subtitle-section-2" dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[2] && this.state.pageData.section[2].item[1], 'shortDescription', this.props.lang) }}></div>
                                                </div>
                                                <div className="col-curoflow-lg-6">
                                                    <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[2] && this.state.pageData.section[2].item[2] && this.state.pageData.section[2].item[2].image)} alt={(this.state.pageData && this.state.pageData.section[2] && this.state.pageData.section[2].item[2] && this.state.pageData.section[2].item[2].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                                </div>
                                            </div>
                                        </section>
                                        :
                                        null
                                }
                                {
                                    this.state.pageData.section4 ?
                                        <section className='bg-grey newbg-boxes'>
                                            <div className="container-curoflow">
                                                <h2>{this.state.pageData && this.state.pageData.section[3] && this.state.pageData.section[3].item[0]?.title[this.props.lang]}</h2>
                                            </div>
                                            <div className="container-curoflow">
                                                <div className="col-curoflow-lg-6 bg-flow-dark">
                                                    <div className='info-flow-box' onClick={() => { window.open(API_ENDPOINT + (this.state.pageData?.section?.[3]?.item?.[1]?.file?.[this.props?.lang?.toLowerCase()]?.file), "_blank") }}>
                                                        <Isvg src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[3] && this.state.pageData.section[3].item[1] && this.state.pageData.section[3].item[1].image)} />
                                                        <h4 className='black-color'>{this.state.pageData && this.state.pageData.section[3] && this.state.pageData.section[3].item[1]?.title[this.props.lang]}</h4>
                                                        <div className='grey-color' dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[3] && this.state.pageData.section[3].item[1], 'shortDescription', this.props.lang) }}></div>
                                                        <div className='text-bottom'>
                                                            <p>{this.state.pageData && this.state.pageData.section[3] && this.state.pageData.section[3].item[1]?.textBottom[this.props.lang]}</p>
                                                            <img src={ArrowForward} alt="" className='img-flow-sec' />
                                                            <img src={ArrowForward1} alt="" className='img-flow-sec1' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-curoflow-lg-6 bg-flow-dark">
                                                    <div className='info-flow-box' onClick={() => { window.open(API_ENDPOINT + (this.state.pageData?.section?.[3]?.item?.[2]?.file?.[this.props?.lang?.toLowerCase()]?.file), "_blank") }}>
                                                        <Isvg src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[3] && this.state.pageData.section[3].item[2] && this.state.pageData.section[3].item[2].image)} />
                                                        <h4 className='black-color'>{this.state.pageData && this.state.pageData.section[3] && this.state.pageData.section[3].item[2]?.title[this.props.lang]}</h4>
                                                        <div className='grey-color' dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[3] && this.state.pageData.section[3].item[2], 'shortDescription', this.props.lang) }}></div>
                                                        <div className='text-bottom'>
                                                            <p>{this.state.pageData && this.state.pageData.section[3] && this.state.pageData.section[3].item[2]?.textBottom[this.props.lang]}</p>
                                                            <img src={ArrowForward} alt="" className='img-flow-sec' />
                                                            <img src={ArrowForward1} alt="" className='img-flow-sec1' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        :
                                        null
                                }
                                {
                                    this.state.pageData.section5 ?
                                        // <section className='curoflow-paddiing owners-curo'>
                                        //     <h1 className='text-center margin-bottom-h1'>{this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[0] && this.state.pageData.section[4].item[0].title[this.props.lang]}</h1>
                                        //     <div className="container-curoflow margin-bottom">
                                        //         <div className="col-curoflow-lg-4 bg-grey-employ">
                                        //             <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[1] && this.state.pageData.section[4].item[1].image)} alt={(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[1] && this.state.pageData.section[4].item[1].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                        //             <div className='mt-employ' dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[1], 'shortDescription', this.props.lang) }}></div>
                                        //         </div>
                                        //         <div className="col-curoflow-lg-4 bg-grey-employ">
                                        //             <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[2] && this.state.pageData.section[4].item[2].image)} alt={(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[2] && this.state.pageData.section[4].item[2].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                        //             <div className='mt-employ' dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[2], 'shortDescription', this.props.lang) }}></div>
                                        //         </div>
                                        //         <div className="col-curoflow-lg-4 bg-grey-employ">
                                        //             <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[3] && this.state.pageData.section[4].item[3].image)} alt={(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[3] && this.state.pageData.section[4].item[3].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                        //             <div className='mt-employ' dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[3], 'shortDescription', this.props.lang) }}></div>
                                        //         </div>

                                        //     </div>
                                        //     <div className="container-curoflow">
                                        //     <div className="col-curoflow-lg-4 bg-grey-employ">
                                        //             <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[4] && this.state.pageData.section[4].item[4].image)} alt={(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[4] && this.state.pageData.section[4].item[4].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                        //             <div className='mt-employ' dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[4], 'shortDescription', this.props.lang) }}></div>
                                        //         </div>
                                        //         <div className="bg-grey-employ col-curoflow-lg-4">
                                        //             <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[5] && this.state.pageData.section[4].item[5].image)} alt={(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[5] && this.state.pageData.section[4].item[5].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                        //             <div className='mt-employ' dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[5], 'shortDescription', this.props.lang) }}></div>
                                        //         </div>
                                        //         <div className="col-curoflow-lg-4 bg-grey-employ">
                                        //             <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[6] && this.state.pageData.section[4].item[6].image)}  alt={(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[6] && this.state.pageData.section[4].item[6].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))}/>
                                        //             <div className='mt-employ'   dangerouslySetInnerHTML={{__html: Object.translate(this.state.pageData && this.state.pageData.section[4]  && this.state.pageData.section[4].item[6], 'shortDescription', this.props.lang)}}></div>
                                        //          </div>


                                        //     </div>
                                        //     <div className='co-workers-div'>
                                        //         <a href="https://jobs.curoflow.se/" target="_blank" className='co-workers'>{"See more of our coworkers".translate(this.props.lang)}</a>
                                        //     </div>

                                        // </section>




                                        <section className='curoflow-paddiing owners-curo'>
                                            <h1 className='text-center margin-bottom-h1'>{this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[0] && this.state.pageData.section[4].item[0].title[this.props.lang]}</h1>
                                            <div className="container-curoflow margin-bottom">
                                                <div className="col-curoflow-lg-3 bg-grey-employ">
                                                    <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[1] && this.state.pageData.section[4].item[1].image)} alt={(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[1] && this.state.pageData.section[4].item[1].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                                    <div className='mt-employ' dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[1], 'shortDescription', this.props.lang) }}></div>
                                                </div>
                                                <div className="col-curoflow-lg-3 bg-grey-employ">
                                                    <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[2] && this.state.pageData.section[4].item[2].image)} alt={(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[2] && this.state.pageData.section[4].item[2].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                                    <div className='mt-employ' dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[2], 'shortDescription', this.props.lang) }}></div>
                                                </div>
                                                <div className="col-curoflow-lg-3 bg-grey-employ">
                                                    <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[3] && this.state.pageData.section[4].item[3].image)} alt={(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[3] && this.state.pageData.section[4].item[3].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                                    <div className='mt-employ' dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[3], 'shortDescription', this.props.lang) }}></div>
                                                </div>
                                                <div className="col-curoflow-lg-3 bg-grey-employ">
                                                    <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[4] && this.state.pageData.section[4].item[4].image)} alt={(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[4] && this.state.pageData.section[4].item[4].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                                    <div className='mt-employ' dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[4], 'shortDescription', this.props.lang) }}></div>
                                                </div>

                                            </div>
                                            <div className="container-curoflow owners">
                                                <div className="col-curoflow-lg-3 bg-grey-employ">
                                                    <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[5] && this.state.pageData.section[4].item[5].image)} alt={(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[5] && this.state.pageData.section[4].item[5].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                                    <div className='mt-employ' dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[5], 'shortDescription', this.props.lang) }}></div>
                                                </div>
                                                <div className="bg-grey-employ col-curoflow-lg-3">
                                                    <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[6] && this.state.pageData.section[4].item[6].image)} alt={(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[6] && this.state.pageData.section[4].item[6].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                                    <div className='mt-employ' dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[6], 'shortDescription', this.props.lang) }}></div>
                                                </div>
                                                <div className="col-curoflow-lg-3 bg-grey-employ">
                                                    <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[7] && this.state.pageData.section[4].item[7].image)} alt={(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[7] && this.state.pageData.section[4].item[7].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                                    <div className='mt-employ' dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[7], 'shortDescription', this.props.lang) }}></div>
                                                </div>
                                                <div className="col-curoflow-lg-3 bg-grey-employ">
                                                    <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[8] && this.state.pageData.section[4].item[8].image)} alt={(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[8] && this.state.pageData.section[4].item[8].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                                    <div className='mt-employ' dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[8], 'shortDescription', this.props.lang) }}></div>
                                                </div>


                                            </div>















                                            <div className="container-curoflow owners">
                                                <div className="col-curoflow-lg-3 bg-grey-employ">
                                                    <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[9] && this.state.pageData.section[4].item[9].image)} alt={(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[9] && this.state.pageData.section[4].item[9].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                                    <div className='mt-employ' dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[9], 'shortDescription', this.props.lang) }}></div>
                                                </div>
                                                <div className="bg-grey-employ col-curoflow-lg-3">
                                                    <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[10] && this.state.pageData.section[4].item[10].image)} alt={(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[10] && this.state.pageData.section[4].item[10].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                                    <div className='mt-employ' dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[10], 'shortDescription', this.props.lang) }}></div>
                                                </div>
                                                <div className="col-curoflow-lg-3 bg-grey-employ">
                                                    <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[11] && this.state.pageData.section[4].item[11].image)} alt={(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[11] && this.state.pageData.section[4].item[11].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                                    <div className='mt-employ' dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[11], 'shortDescription', this.props.lang) }}></div>
                                                </div>
                                                <div className="col-curoflow-lg-3 bg-grey-employ">
                                                    <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[12] && this.state.pageData.section[4].item[12].image)} alt={(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[12] && this.state.pageData.section[4].item[12].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                                    <div className='mt-employ' dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[4] && this.state.pageData.section[4].item[12], 'shortDescription', this.props.lang) }}></div>
                                                </div>


                                            </div>












                                            <div className='co-workers-div'>
                                                <a href="https://jobs.curoflow.se/" target="_blank" className='co-workers'>{"See more of our coworkers".translate(this.props.lang)}</a>
                                            </div>

                                        </section>
                                        :
                                        null
                                }

                                {
                                    this.state.pageData.section6 ?

                                        <section className='sectionTextBigimg sectionTextBigImgNoBg'>
                                            <div className="title">
                                                <h1>{this.state.pageData && this.state.pageData.section[5] && this.state.pageData.section[5].item[0]?.title[this.props.lang]}</h1>
                                            </div>
                                            <img src={CuroflowIcon} className="rightSide" alt="Curoflow Digital Telemedicine Platform Icon" />
                                        </section>

                                        :
                                        null
                                }
                                {
                                    this.state.pageData.section7 ?
                                        <section className='section-partners1 margin-bottom-partners'>
                                            <div className="title">
                                                <h2>{this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[0]?.title[this.props.lang]}</h2>
                                                <h6>{this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[0]?.subTitle[this.props.lang]}</h6>

                                            </div>
                                            <h3 className='title-partners'>{this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[0]?.subTitle1[this.props.lang]}</h3>
                                            <div className="container-curoflow container-logo-curoflow">
                                                <div className="col-curoflow-lg-3">
                                                    <a href="https://www.svenskavard.se/" target="_blank">   <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[1] && this.state.pageData.section[6].item[1].image)} className="img-margin img-margin-logo" alt={(this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[1] && this.state.pageData.section[6].item[1].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />  </a>
                                                    <a href="https://acceptus.se/" target="_blank"> <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[5] && this.state.pageData.section[6].item[5].image)} className="img-margin img-margin-logo" alt={(this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[5] && this.state.pageData.section[6].item[5].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} /> </a>
                                                    <a href="https://www.webdoc.com/journalsystem" target="_blank"> <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[9] && this.state.pageData.section[6].item[9].image)} className="img-margin img-margin-logo" alt={(this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[9] && this.state.pageData.section[6].item[9].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} /></a>
                                                    {/* <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[12] && this.state.pageData.section[6].item[12].image)} className="img-margin img-margin-logo" alt={(this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[12] && this.state.pageData.section[6].item[12].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                                <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[2] && this.state.pageData.section[6].item[2].image)} className="img-margin img-margin-logo" alt={(this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[2] && this.state.pageData.section[6].item[2].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                                <a href="https://carasent.se/" target="_blank"> <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[3] && this.state.pageData.section[6].item[3].image)} className="img-margin img-margin-logo" alt={(this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[3] && this.state.pageData.section[6].item[3].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} /></a> */}
                                                    <a href="https://slf.se/" target="_blank">  <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[13] && this.state.pageData.section[6].item[13].image)} className="img-margin img-margin-logo" alt={(this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[13] && this.state.pageData.section[6].item[13].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} /> </a>
                                                    <a href="https://www.insidercx.com/" target="_blank"> <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[15] && this.state.pageData.section[6].item[15].image)} className="img-margin img-margin-logo new-logo" alt={(this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[15] && this.state.pageData.section[6].item[15].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} /></a>
                                                </div>
                                                <div className="col-curoflow-lg-3">
                                                    <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[2] && this.state.pageData.section[6].item[2].image)} className="img-margin img-margin-logo" alt={(this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[2] && this.state.pageData.section[6].item[2].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                                    <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[6] && this.state.pageData.section[6].item[6].image)} className="img-margin img-margin-logo" alt={(this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[6] && this.state.pageData.section[6].item[6].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                                    <a href="https://glesys.se/" target="_blank">      <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[10] && this.state.pageData.section[6].item[10].image)} className="img-margin img-margin-logo" alt={(this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[10] && this.state.pageData.section[6].item[10].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} /> </a>

                                                    {/* <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[6] && this.state.pageData.section[6].item[6].image)} className="img-margin img-margin-logo" alt={(this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[6] && this.state.pageData.section[6].item[6].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                            
                                                <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[5] && this.state.pageData.section[6].item[5].image)} className="img-margin img-margin-logo" alt={(this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[5] && this.state.pageData.section[6].item[5].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                                <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[6] && this.state.pageData.section[6].item[6].image)} className="img-margin img-margin-logo" alt={(this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[6] && this.state.pageData.section[6].item[6].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} /> */}
                                                    <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[14] && this.state.pageData.section[6].item[14].image)} className="img-margin img-margin-logo" alt={(this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[14] && this.state.pageData.section[6].item[14].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                                </div>
                                                <div className="col-curoflow-lg-3">




                                                    <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[3] && this.state.pageData.section[6].item[3].image)} className="img-margin img-margin-logo" alt={(this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[3] && this.state.pageData.section[6].item[3].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                                    <a href="https://tillvaxtverket.se/" target="_blank">        <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[7] && this.state.pageData.section[6].item[7].image)} className="img-margin img-margin-logo" alt={(this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[7] && this.state.pageData.section[6].item[7].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} /> </a>

                                                    <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[11] && this.state.pageData.section[6].item[11].image)} className="img-margin img-margin-logo" alt={(this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[11] && this.state.pageData.section[6].item[11].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />

                                                </div>
                                                <div className="col-curoflow-lg-3">
                                                    <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[4] && this.state.pageData.section[6].item[4].image)} className="img-margin img-margin-logo" alt={(this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[4] && this.state.pageData.section[6].item[4].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                                    {/* <a href="https://www.svenskavard.se/" target="_blank"> <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[10] && this.state.pageData.section[6].item[10].image)} className="img-margin img-margin-logo" alt={(this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[10] && this.state.pageData.section[6].item[10].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} /></a> */}
                                                    <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[8] && this.state.pageData.section[6].item[8].image)} className="img-margin img-margin-logo" alt={(this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[8] && this.state.pageData.section[6].item[8].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                                    {/* <a href="https://acceptus.se/" target="_blank"> <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[9] && this.state.pageData.section[6].item[9].image)} className="img-margin img-margin-logo" alt={(this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[9] && this.state.pageData.section[6].item[9].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} /></a> */}
                                                    <a href="https://itomsorg.se/" target="_blank">           <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[12] && this.state.pageData.section[6].item[12].image)} className="img-margin img-margin-logo" alt={(this.state.pageData && this.state.pageData.section[6] && this.state.pageData.section[6].item[12] && this.state.pageData.section[6].item[12].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} /> </a>
                                                </div>
                                            </div>

                                        </section>
                                        :
                                        null
                                }
                                {
                                    this.state.pageData.section8 ?
                                        <section className='section-partners1'>
                                            <div className="title">
                                                <h2>{this.state.pageData && this.state.pageData.section[7] && this.state.pageData.section[7].item[0]?.title[this.props.lang]}</h2>
                                                <h6>{this.state.pageData && this.state.pageData.section[7] && this.state.pageData.section[7].item[0]?.subTitle[this.props.lang]}</h6>

                                            </div>
                                            <h3 className='title-partners'>{this.state.pageData && this.state.pageData.section[7] && this.state.pageData.section[7].item[0]?.subTitle1[this.props.lang]}</h3>

                                            <div className="container-curoflow container-logo-curoflow">
                                                <div className='img-partners col-curoflow-lg-3'>
                                                    <a href="https://www.almi.se/riskkapital/" target="_blank">    <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[7] && this.state.pageData.section[7].item[1] && this.state.pageData.section[7].item[1].image)} className="img-margin" alt={(this.state.pageData && this.state.pageData.section[7] && this.state.pageData.section[7].item[1] && this.state.pageData.section[7].item[1].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} /> </a>
                                                    <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[7] && this.state.pageData.section[7].item[5] && this.state.pageData.section[7].item[5].image)} className="img-margin" alt={(this.state.pageData && this.state.pageData.section[7] && this.state.pageData.section[7].item[5] && this.state.pageData.section[7].item[5].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                                </div>

                                                <div className='img-partners col-curoflow-lg-3'>
                                                    <a href='https://www.impactinvest.se/' target="_blank">   <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[7] && this.state.pageData.section[7].item[2] && this.state.pageData.section[7].item[2].image)} className="img-margin" alt={(this.state.pageData && this.state.pageData.section[7] && this.state.pageData.section[7].item[2] && this.state.pageData.section[7].item[2].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} /> </a>
                                                    <a href='https://silicongardens.com/' target="_blank">   <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[7] && this.state.pageData.section[7].item[6] && this.state.pageData.section[7].item[6].image)} className="img-margin" alt={(this.state.pageData && this.state.pageData.section[7] && this.state.pageData.section[7].item[6] && this.state.pageData.section[7].item[6].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} /> </a>

                                                </div>
                                                <div className='img-partners col-curoflow-lg-3'>
                                                    <a href="https://www.curus.se/" target="_blank">   <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[7] && this.state.pageData.section[7].item[3] && this.state.pageData.section[7].item[3].image)} className="img-margin" alt={(this.state.pageData && this.state.pageData.section[7] && this.state.pageData.section[7].item[3] && this.state.pageData.section[7].item[3].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} /></a>
                                                    <a href="https://www.linbo.se" target="_blank">   <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[7] && this.state.pageData.section[7].item[7] && this.state.pageData.section[7].item[7].image)} className="img-margin" alt={(this.state.pageData && this.state.pageData.section[7] && this.state.pageData.section[7].item[7] && this.state.pageData.section[7].item[7].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} /></a>
                                                </div>

                                                <div className='img-partners col-curoflow-lg-3'>
                                                    <a href="https://www.4c.se" target="_blank">    <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[7] && this.state.pageData.section[7].item[4] && this.state.pageData.section[7].item[4].image)} className="img-margin" alt={(this.state.pageData && this.state.pageData.section[7] && this.state.pageData.section[7].item[4] && this.state.pageData.section[7].item[4].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} /> </a>
                                                </div>


                                                {/* <div className='img-partners'>
                                                <Img src={API_ENDPOINT + (this.state.pageData && this.state.pageData.section[7] && this.state.pageData.section[7].item[5] && this.state.pageData.section[7].item[5].image)} className="img-margin" alt={(this.state.pageData && this.state.pageData.section[7] && this.state.pageData.section[7].item[5] && this.state.pageData.section[7].item[5].image?.replace('/uploads/', '').replace('_', ' ').slice(0, -4))} />
                                            </div> */}





                                            </div>
                                        </section>
                                        :
                                        null
                                }

                                {
                                    this.state.pageData.section8 ?

                                        <ContactForm title={this.state.pageData && this.state.pageData.section[8] && this.state.pageData.section[8].item[0]?.title[this.props.lang]} text={this.state.pageData && this.state.pageData.section[8] && this.state.pageData.section[8].item[0] ? Object.translate(this.state.pageData && this.state.pageData.section[8] && this.state.pageData.section[8].item[0], 'shortDescription', this.props.lang) : null} class={'grey'} onSubmit={this.sendMessage2} lang={this.props.lang} contactFormError={this.state.contactFormError} contactFormMessageError={this.state.contactFormMessageError} />

                                        :
                                        null
                                }

                                {
                                    this.state.pageData.section && this.state.pageData.section[9] ?
                                        <div>
                                            <section className='section-goflow'>
                                                <div className='container-curoflow'>
                                                    <div className="title">
                                                        <h1>{this.state.pageData && this.state.pageData.section[9] && this.state.pageData.section[9].item[0] && this.state.pageData.section[9].item[0].title && this.state.pageData.section[9].item[0].title[this.props.lang]}</h1>
                                                        <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[9] && this.state.pageData.section[9].item[0], 'shortDescription', this.props.lang) }}></div>
                                                    </div>
                                                </div>

                                            </section>
                                            <section className='section-black-noflow curoflow-section-box'>
                                                <div className='container-curoflow'>
                                                    <div className='col-lg-4'>
                                                        <div className='content-noflowtitle' dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[9] && this.state.pageData.section[9].item[0], 'shortDescription1', this.props.lang) }}></div>
                                                    </div>
                                                    <div className='col-lg-8'>
                                                        <h5 className='noflow-title1'>{this.state.pageData && this.state.pageData.section[9] && this.state.pageData.section[9].item[0] && this.state.pageData.section[9].item[0].title1 && this.state.pageData.section[9].item[0].title1[this.props.lang]}</h5>
                                                        <div className='content-noflow'>
                                                            <div className='content-noflow-box'>
                                                                <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[9] && this.state.pageData.section[9].item[0], 'shortDescription2', this.props.lang) }}></div>
                                                            </div>
                                                            <div className='content-noflow-box'>
                                                                <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[9] && this.state.pageData.section[9].item[0], 'shortDescription5', this.props.lang) }}></div>
                                                            </div>
                                                        </div>
                                                        <div className='content-noflow'>
                                                            <div className='content-noflow-box'>
                                                                <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[9] && this.state.pageData.section[9].item[0], 'shortDescription3', this.props.lang) }}></div>
                                                            </div>
                                                            <div className='content-noflow-box'>
                                                                <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[9] && this.state.pageData.section[9].item[0], 'shortDescription6', this.props.lang) }}></div>
                                                            </div>
                                                        </div>
                                                        <div className='content-noflow last-flow'>
                                                            <div className='content-noflow-box'>
                                                                <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[9] && this.state.pageData.section[9].item[0], 'shortDescription4', this.props.lang) }}></div>
                                                            </div>
                                                            <div className='content-noflow-box no-content'>
                                                                <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[9] && this.state.pageData.section[9].item[0], 'shortDescription6', this.props.lang) }}></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </section>
                                            <section className='section-grey-goflow curoflow-section-box'>
                                                <div className='container-curoflow'>
                                                    <div className='col-lg-4'>
                                                        <div className='content-noflowtitle' dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[9] && this.state.pageData.section[9].item[0], 'shortDescription7', this.props.lang) }}></div>
                                                    </div>
                                                    <div className='col-lg-8'>
                                                        <h5 className='noflow-title1'>{this.state.pageData && this.state.pageData.section[9] && this.state.pageData.section[9].item[0] && this.state.pageData.section[9].item[0].title2 && this.state.pageData.section[9].item[0].title2[this.props.lang]}</h5>
                                                        <div className='content-noflow'>
                                                            <div className='content-noflow-box'>
                                                                <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[9] && this.state.pageData.section[9].item[0], 'shortDescription8', this.props.lang) }}></div>
                                                            </div>
                                                            <div className='content-noflow-box'>
                                                                <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[9] && this.state.pageData.section[9].item[0], 'shortDescription12', this.props.lang) }}></div>
                                                            </div>
                                                        </div>
                                                        <div className='content-noflow'>
                                                            <div className='content-noflow-box'>
                                                                <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[9] && this.state.pageData.section[9].item[0], 'shortDescription9', this.props.lang) }}></div>
                                                            </div>
                                                            <div className='content-noflow-box'>
                                                                <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[9] && this.state.pageData.section[9].item[0], 'shortDescription13', this.props.lang) }}></div>
                                                            </div>
                                                        </div>
                                                        <div className='content-noflow'>
                                                            <div className='content-noflow-box'>
                                                                <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[9] && this.state.pageData.section[9].item[0], 'shortDescription10', this.props.lang) }}></div>
                                                            </div>
                                                            <div className='content-noflow-box'>
                                                                <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[9] && this.state.pageData.section[9].item[0], 'shortDescription14', this.props.lang) }}></div>
                                                            </div>
                                                        </div>
                                                        <div className='content-noflow last-flow'>
                                                            <div className='content-noflow-box'>
                                                                <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[9] && this.state.pageData.section[9].item[0], 'shortDescription11', this.props.lang) }}></div>
                                                            </div>
                                                            <div className='content-noflow-box'>
                                                                <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.pageData && this.state.pageData.section[9] && this.state.pageData.section[9].item[0], 'shortDescription15', this.props.lang) }}></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </section>
                                            <ContactFormm title={this.state.pageData && this.state.pageData.section[9] && this.state.pageData.section[9].item[0] && this.state.pageData.section[9].item[0].shortDescription16 && this.state.pageData.section[9].item[0].shortDescription16[this.props.lang]} text={this.state.pageData && this.state.pageData.section[9] && this.state.pageData.section[9].item[0] && this.state.pageData.section[9].item[0].shortDescription17 && this.state.pageData.section[9].item[0].shortDescription17[this.props.lang]} class={'grey'} justShowForm={true} lang={this.props.lang} onSubmit={this.sendMessage27} contactFormError={this.state.contactFormError27} contactFormMessageError={this.state.contactFormMessageError27} />
                                        </div>
                                        :
                                        null
                                }

                            </div>
                            :
                            null
                    }

                </div>
                {this.state.playVideo ? <VideoPlayer
                    videoUrl={`/videos/${encodeURIComponent('We_care_about_care_Curoflow.mp4')}`}
                    onClose={(e) => {
                        e?.stopPropagation?.();
                        this.setState({ playVideo: false })
                    }}
                /> : null}
            </>
        );
    }
}

export default Page(AboutUs);
